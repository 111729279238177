/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ActivityDetailsTable from "components/ActivityDetailsTable/ActivityDetailsTable.jsx";
import { getActivities, updateActivity } from "services/userDataProvider.js";
import { successColor } from "assets/jss/material-dashboard-react.jsx";
import { DATE_DISPLAY_FORMAT_DETAILED, dayjs } from "utils/time.jsx";
import {
  metersPerSecondToMinutesPerKm,
  secondsToTime,
  fixCadence
} from "../../utils/runningConverters.js";

const activityStyles = {
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  cardCategory: {
    color: "#999999",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: "#3C4858",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  userActivities: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  userActivity: {
    width: "350px"
  },
  activityKeyDetailsOuterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    columnGap: "10px"
  },
  activityKeyDetailsDistance: {
    fontSize: "larger",
    color: "crimson"
  },
  activityPlanContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "-10px",
    width: "-webkit-fill-available",
    maxWidth: "400px"
  },
  notesAndActionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: "10px"
  },
  notesActionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  activityActionsForm: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start"
  },
  planTitle: {
    color: "crimson"
  },
  planTargetStatus: {
    color: "crimson"
  }
};

class Dashboard extends React.Component {
  state = {
    activityDetails: {},
    activityNotes: {},
    showAllNotes: true,
    allowNotesEditing: false,
    allowPlanEditing: false
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const params = this.props.match && this.props.match.params;
    const activityId = params && params.activityId;
    getActivities(this.props.axios, null, null, null, activityId).then(data =>
      this.setState({
        activityDetails: data,
        activityNotes: data.activity_notes,
        activityPlan: data.activity_plan
      })
    );
  }

  handleNotesSwitchChange = noteType => event => {
    if (event !== undefined) {
      if (noteType === "notes") {
        const showNotes = event.target.checked;
        const editAllowed = showNotes ? this.state.allowNotesEditing : false;
        this.setState({
          allowNotesEditing: editAllowed,
          showAllNotes: showNotes
        });
      } else if (noteType === "plan") {
        if (this.state.allowPlanEditing) {
          this.onNotesUpdate(event);
        }
        this.setState({ allowPlanEditing: !this.state.allowPlanEditing });
      } else if (noteType === "cancel-plan-save") {
        this.setState({ allowPlanEditing: !this.state.allowPlanEditing });
      }
    }
  };

  handleNotesEditSwitch = event => {
    const editAllowed = event.target.checked;
    const showNotes = editAllowed ? true : this.state.showAllNotes;
    this.setState({
      allowNotesEditing: editAllowed,
      showAllNotes: showNotes
    });
  };

  noteUpdated = noteType => e => {
    e.stopPropagation();
    const activityNotes = this.state.activityNotes || {};
    activityNotes[noteType] = e.target.value;
    this.setState({ activityNotes: activityNotes });
  };

  planUpdated = noteType => e => {
    e.stopPropagation();
    const activityPlan = this.state.activityPlan || {};
    activityPlan[noteType] = e.target.value;
    this.setState({ activityPlan: activityPlan });
  };

  onNotesUpdate = e => {
    e.stopPropagation();
    const activityNotes = this.state.activityNotes;
    const activityPlan = this.state.activityPlan;
    updateActivity(this.props.axios, this.state.activityDetails["activity"], {
      activityNotes: activityNotes,
      activityPlan: activityPlan
    }).then(data =>
      this.setState({
        activityDetails: data,
        activityNotes: data.activity_notes,
        activityPlan: data.activity_plan
      })
    );
  };

  onSplitNotesUpdate = (splitId, note) => {
    const activityNotes = this.state.activityNotes || {};
    const activityPlan = this.state.activityPlan || {};
    let splitNotes = activityNotes["splits"] || {};
    splitNotes[splitId] = note;
    activityNotes["splits"] = splitNotes;
    this.setState({ activityNotes: activityNotes, activityPlan: activityPlan });
    updateActivity(this.props.axios, this.state.activityDetails["activity"], {
      activityNotes: activityNotes,
      activityPlan: activityPlan
    }).then(data =>
      this.setState({
        activityDetails: data,
        activityNotes: data.activity_notes,
        activityPlan: data.activity_plan
      })
    );
  };

  renderPlanDescription = description => {
    if (!description) return null;
    const keyNames = ["Warm up:", "Workout:", "Cool down:"];
    keyNames.forEach(keyName => {
      description = description.replace(keyName, ";;; " + keyName);
    });
    const lines = description.split(";;;");
    return (
      <ul>
        {lines.filter(line => line.length > 0).map((line, idx) => {
          return <li key={idx}>{line}</li>;
        })}
      </ul>
    );
  };

  renderActivityDetails() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const activityData = this.state.activityDetails;
    const activityNotes = this.state.activityNotes;
    const activityPlan = this.state.activityPlan;

    if (activityData === undefined || activityData === null) {
      return null;
    }

    let splitDetailsHeader = {
      "Lap Count": "lap_index",
      // "Start Time": "start_time_gmt",
      Distance: "distance_meters",
      Duration: "duration_seconds",
      "Avg Pace": "average_pace",
      "Avg HR": "avg_heart_rate",
      "Stride Length": "stride_length",
      Cadence: "avg_run_cadence",
      "vO2 Max": "vO2_max_value",
      "Elevation Gain": "elevation_gain"
    };

    let splitDetails = [];
    let splitDetailsPresent =
      activityData.splits_details && activityData.splits_details.length > 0;
    if (splitDetailsPresent) {
      splitDetails = activityData.splits_details;
      splitDetails.sort((x, y) => x.lap_index - y.lap_index);
      splitDetails = splitDetails.map(detail => {
        return {
          ...detail,
          average_pace: metersPerSecondToMinutesPerKm(detail["average_speed"]),
          avg_run_cadence: fixCadence(
            activityData["platform"],
            activityData["activity_type"],
            detail["avg_run_cadence"]
          ),
          distance_meters: (detail["distance_meters"] / 1000).toFixed(2),
          duration_seconds: secondsToTime(detail["duration_seconds"]),
          stride_length:
            detail["stride_length"] && detail["stride_length"].toFixed(1)
        };
      });
    }

    let activityIcon = <DirectionsRun />;
    let activity_type = activityData.activity_type
      ? activityData.activity_type.toLowerCase()
      : "";

    if (activity_type.includes("ride")) {
      activityIcon = <DirectionsBike />;
    } else if (activity_type.includes("weight")) {
      activityIcon = <FitnessCenter />;
    }
    return (
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">{activityIcon}</CardIcon>
          <h3 className={classes.cardTitle}>{activityData.activity_name}</h3>
          <p className={classes.cardCategory}>
            {dayjs(activityData.start_time_gmt).format(
              DATE_DISPLAY_FORMAT_DETAILED
            )}
          </p>
        </CardHeader>
        <CardBody>
          <div className={classes.activityKeyDetailsOuterContainer}>
            <div className={classes.activityKeyDetailsContainer}>
              <div className={classes.activityKeyDetailsDistance}>
                <strong>Total Distance</strong>{" "}
                {(activityData.distance_meters / 1000).toFixed(1)} kms
              </div>
              <strong>Total Time</strong>{" "}
              {(activityData.duration_seconds / 60).toFixed(1)} minutes
              <br />
              {activityData.aerobic_training_effect != null &&
                activityData.anaerobic_training_effect != null && (
                  <div className={classes.trainingEffect}>
                    <strong>Training Effect</strong>{" "}
                    {`${activityData.aerobic_training_effect.toFixed(
                      1
                    )}, ${activityData.anaerobic_training_effect.toFixed(1)}`}
                  </div>
                )}
              {activityData.vO2_max_value != null && (
                <div className={classes.vO2Max}>
                  <strong>VO2 Max</strong> {activityData.vO2_max_value}
                </div>
              )}
              <strong>Average Speed</strong>{" "}
              {(activityData.average_speed * 3.6).toFixed(2)} kms/hr
              <br />
              <strong>Average Heart Rate</strong> {activityData.avg_heart_rate}{" "}
              <br />
              <strong>Average Cadence</strong>{" "}
              {fixCadence(
                activityData["platform"],
                activityData["activity_type"] || "",
                activityData["avg_running_cadence_in_steps_per_minute"]
              )}{" "}
              <br />
              <strong>Source</strong> {activityData.platform} <br />
            </div>
            <div className={classes.activityPlanContainer}>
              {this.state.allowPlanEditing === true ? (
                <div className={classes.notesAndActionContainer}>
                  <strong>Plan:</strong>
                  <div className={classes.notesContainer}>
                    <textarea
                      className="textarea"
                      value={activityPlan && activityPlan["description"]}
                      name="activity-plan"
                      placeholder="Add details"
                      onChange={this.planUpdated("description")}
                    />
                  </div>
                </div>
              ) : (
                <div className={classes.notesAndActionContainer}>
                  <strong>
                    Plan:{" "}
                    <span className={classes.planTitle}>
                      {activityPlan && activityPlan.title
                        ? `${activityPlan["title"]} Kms`
                        : "No plan specified"}
                    </span>{" "}
                  </strong>
                  {activityPlan &&
                    activityPlan["status"] && (
                      <strong className={classes.planTargetStatus}>
                        {activityPlan["status"]}
                      </strong>
                    )}
                  <span>
                    {activityPlan
                      ? this.renderPlanDescription(activityPlan["description"])
                      : ""}
                  </span>
                </div>
              )}
              <div className={classes.notesActionContainer}>
                <Button
                  color="info"
                  onClick={this.handleNotesSwitchChange("plan")}
                >
                  {this.state.allowPlanEditing ? (
                    <span className="material-icons">save</span>
                  ) : (
                    <span className="material-icons">edit</span>
                  )}
                </Button>
                {this.state.allowPlanEditing && (
                  <Button
                    color="info"
                    onClick={this.handleNotesSwitchChange("cancel-plan-save")}
                  >
                    <span className="material-icons">cancel</span>
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className={classes.activityDetailsControlContainer}>
            <FormGroup className={classes.activityActionsForm}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.showAllNotes}
                    onChange={this.handleNotesSwitchChange("notes")}
                  />
                }
                label="Show all split notes"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.allowNotesEditing}
                    onChange={this.handleNotesEditSwitch}
                  />
                }
                label="Edit split notes"
              />
            </FormGroup>
          </div>
          {splitDetailsPresent && (
            <ActivityDetailsTable
              headersMap={splitDetailsHeader}
              rowsData={splitDetails}
              activityNotes={activityNotes && (activityNotes["splits"] || {})}
              avgSpeed={activityData.average_speed}
              showAllNotes={this.state.showAllNotes}
              allowNotesEditing={this.state.allowNotesEditing}
              onNotesUpdate={this.onSplitNotesUpdate}
            />
          )}
          {this.state.allowNotesEditing === true ? (
            <div className={classes.notesAndActionContainer}>
              <strong>Notes:</strong>
              <div className={classes.notesContainer}>
                <textarea
                  className="textarea"
                  value={activityNotes && activityNotes["summary"]}
                  name="comment"
                  placeholder="Add note"
                  onChange={this.noteUpdated("summary")}
                />
              </div>
              <div className={classes.notesActionContainer}>
                <Button color="info" onClick={this.onNotesUpdate}>
                  <span className="material-icons">save</span>
                </Button>
              </div>
            </div>
          ) : (
            <div className={classes.notesAndActionContainer}>
              <strong>Notes:</strong>
              <span>{activityNotes && activityNotes["summary"]}</span>
            </div>
          )}
        </CardBody>
        <CardFooter stats>
          <Button
            color="info"
            size="sm"
            className={classes.marginRight}
            onClick={e => this.props.history.push("/activities")}
          >
            Activities
          </Button>
        </CardFooter>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return <div>{this.renderActivityDetails()}</div>;
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(activityStyles)(Dashboard);
