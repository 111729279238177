/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Chat from "components/Chat/Chat.js";

const styles = {};

class ChatPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: false
    };
  }

  // eslint-disable-next-line no-unused-vars
  handleClick = name => event => {};

  render() {
    let targetRoom = this.props.match.params && this.props.match.params.user;
    if (targetRoom === undefined) {
      targetRoom = this.props.user.username;
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {targetRoom ? (
            <Chat
              currentUser={this.props.user.username}
              targetRoom={targetRoom}
            />
          ) : null}
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ChatPage);
