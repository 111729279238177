import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Chart from "components/Chart/ECharts.jsx";

import { getUserWidgets } from "../../services/dashboardDataProvider";

// import { MEDIA_ROOT } from "config.js";

const dashboardStyle = {
  dashboardContainer: {
    width: "-webkit-fill-available"
  },
  ExternalDashboardContainer: {
    width: "100%"
  },
  ExternalDashboardTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "large",
    paddingTop: "25px",
    paddingBottom: "25px",
    display: "none"
  },
  ExternalDashboardROW: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "warp",
    width: "100%"
  },
  ExternalDashboardChart: {
    width: "100%"
  },
  ExternalDashboardChartTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "large",
    paddingBottom: "25px"
  },
  trainingWidgets: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "-webkit-fill-available"
  },
  trainingWidget: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "-webkit-fill-available"
  },
  trainingWidgetHeader: {
    padding: "15px !important",
    marginTop: "-20px !important",
    borderRadius: "3px !important",
    background: "linear-gradient(60deg, #275b95, #00acc1) !important",
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4) !important"
  },
  trainingWidgetTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "large"
  }
};

class SupersetDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgets: []
    };
  }

  updateUserData() {
    // eslint-disable-next-line react/prop-types
    getUserWidgets(this.props.axios, this.props.userId)
      .then(data => {
        this.setState({
          widgets: data.results,
          userId: this.props.userId,
          shouldUpdate: false
        });
      })
      .catch(err => {
        console.log("An error occurred while retrieving user widgets. ", err);
        this.setState({
          widgets: [],
          userId: this.props.userId,
          shouldUpdate: false,
          error: true
        });
      });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.userId !== state.userId) {
      state["userId"] = nextProps.userId;
      state["widgets"] = [];
      state["shouldUpdate"] = true;
    }
    return state;
  }

  renderExternalDashboard() {
    const { classes } = this.props;
    const widgets = this.state.widgets || [];
    let dashboardWidget = widgets.find(
      widgetData =>
        widgetData.widget &&
        widgetData.widget.source === "superset" &&
        widgetData.widget_data
    );

    if (dashboardWidget == null) return null;

    let dashboardWidgetData = dashboardWidget.widget_data;

    return (
      <div className={classes.ExternalDashboardContainer}>
        <div className={classes.ExternalDashboardTitle}>
          {dashboardWidgetData.title}
        </div>
        {this.renderDashItem(
          Object.keys(dashboardWidgetData.position).map(
            key => dashboardWidgetData.position[key]
          )
        )}
      </div>
    );
  }

  renderDashItem(items = null, parent = null) {
    // const { classes } = this.props;

    if (parent === null) {
      parent = items && items.find(item => item.type === "ROOT");
    } else {
      parent = items && items.find(item => item.id === parent);
    }

    if (parent === null) return null;

    let children = parent.children;

    return (
      <div
        key={`${parent.id}-${parent.type}`}
        className={`ExternalDashboard${parent.type}`}
      >
        {parent.type === "CHART" && this.renderExternalChart(parent)}
        {children &&
          children.map(childItem => {
            return this.renderDashItem(items, childItem);
          })}
      </div>
    );
  }

  renderExternalChart(chartPositionData) {
    const { classes } = this.props;
    const dashboardWidget =
      this.state.widgets &&
      this.state.widgets.find(
        widgetData =>
          widgetData.widget &&
          widgetData.widget_data &&
          widgetData.widget.source === "superset"
      );
    const chartData =
      dashboardWidget.widget_data.charts &&
      dashboardWidget.widget_data.charts.find(chart =>
        chartPositionData.meta.sliceName.includes(chart.name)
      );
    if (
      chartData == null ||
      chartData.data == null ||
      chartData.data.colnames == null ||
      chartData.data.colnames.length < 1
    ) {
      return null;
    }

    if (chartData.data.data == null || chartData.data.data.length < 1) {
      return null;
    }

    return (
      <div
        key={`${chartPositionData.meta.chartId}`}
        className={classes.ExternalDashboardChart}
        // style={{
        //   height: chartPositionData.meta.height,
        //   width: chartPositionData.meta.width
        // }}
      >
        <div className={classes.ExternalDashboardChartTitle}>
          {chartData.name}
        </div>
        <Chart type="chart" chartConfig={chartData} />
      </div>
    );
  }

  renderPlanDescription = description => {
    const keyNames = ["Warm up:", "Workout:", "Cool down:"];
    keyNames.forEach(keyName => {
      description = description.replace(keyName, ";;; " + keyName);
    });
    const lines = description.split(";;;");
    return (
      <ul>
        {lines.filter(line => line.trim().length > 0).map((line, idx) => {
          return <li key={idx}>{line}</li>;
        })}
      </ul>
    );
  };

  renderTrainingPaces = metadata => {
    const { other_data } = metadata;
    const vdotTrainingPaces = other_data && other_data.vdotTrainingPaces;
    return (
      vdotTrainingPaces && (
        <ul>
          <li>
            Easy training pace: <strong>{vdotTrainingPaces["easy"]}</strong>
          </li>
          <li>
            Marathon training pace:{" "}
            <strong>{vdotTrainingPaces["marathon"]}</strong>
          </li>
          <li>
            Threshold training pace:{" "}
            <strong>{vdotTrainingPaces["threshold"]}</strong>
          </li>
          <li>
            Interval training pace:{" "}
            <strong>{vdotTrainingPaces["interval"]}</strong>
          </li>
          <li>
            Repetition training pace:{" "}
            <strong>{vdotTrainingPaces["repetition"]}</strong>
          </li>
        </ul>
      )
    );
  };

  renderHealthMetrics() {
    const { classes } = this.props;
    const widgets = this.state.widgets || [];
    let trainingWidgets = widgets.filter(
      widgetData =>
        widgetData.widget &&
        widgetData.widget.source === "user_health_metrics" &&
        widgetData.widget.metadata &&
        widgetData.widget.metadata.active === true
    );
    if (trainingWidgets && trainingWidgets.length === 0) return null;
    return (
      trainingWidgets && (
        <div className={classes.trainingWidgets}>
          <div className={classes.trainingWidgetTitle}>Your training Paces</div>
          {trainingWidgets.map((trainingWidget, idx) => {
            return (
              <Card className={classes.trainingWidget} key={idx}>
                <CardHeader className={classes.trainingWidgetHeader} stats>
                  Current VO2 Max:{" "}
                  <strong>{trainingWidget.widget.metadata.vo2_max}</strong>
                </CardHeader>
                <CardBody>
                  {this.renderTrainingPaces(trainingWidget.widget.metadata)}
                </CardBody>
              </Card>
            );
          })}
        </div>
      )
    );
  }

  renderTrainingPlan() {
    const { classes } = this.props;
    const widgets = this.state.widgets || [];
    let trainingWidgets = widgets.filter(
      widgetData =>
        widgetData.widget &&
        widgetData.widget.source === "user_calender" &&
        widgetData.widget.type === "Training"
    );
    if (trainingWidgets && trainingWidgets.length === 0) return null;
    return (
      trainingWidgets && (
        <div className={classes.trainingWidgets}>
          <div className={classes.trainingWidgetTitle}>
            Todays training plan:
          </div>
          {trainingWidgets.map((trainingWidget, idx) => {
            return (
              <Card className={classes.trainingWidget} key={idx}>
                <CardHeader className={classes.trainingWidgetHeader} stats>
                  <span>
                    {trainingWidget.widget.name}{" "}
                    {trainingWidget.widget.name !== "Rest" ? "Kms" : ""}{" "}
                  </span>
                </CardHeader>
                <CardBody>
                  {this.renderPlanDescription(
                    trainingWidget.widget.description
                  )}
                </CardBody>
              </Card>
            );
          })}
        </div>
      )
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.shouldUpdate === true) {
      this.updateUserData();
    }
    if (this.state.error === true) {
      return <div>Error loading dashboard</div>;
    }
    return (
      <div className={classes.dashboardContainer}>
        {this.renderTrainingPlan()}
        {this.renderHealthMetrics()}
        {this.renderExternalDashboard()}
      </div>
    );
  }
}

SupersetDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  userId: PropTypes.number,
  history: PropTypes.any
};

export default withStyles(dashboardStyle)(SupersetDashboard);
