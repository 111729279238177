/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";

import { MadeWithLove } from "components/MadeWithLove/MadeWithLove.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { login } from "services/authProvider.js";

import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";

import { API_ROOT } from "config.js";
import StravaButton from "assets/img/btn_strava_connectwith_orange.png";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    };
  }

  componentDidMount() {
    let searchPath = this.props.location && this.props.location.search;
    if (searchPath) {
      let tempList = searchPath.split("redirectTo=");
      let redirectUrl = tempList.length > 1 ? tempList[1] : null;
      // console.log("redirectUrl", redirectUrl);
      if (redirectUrl) {
        this.setState({ redirectUrl: redirectUrl });
      }
    }
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    this.setState({ errorMessage: null });
    login(this.state.username, this.state.password)
      .then(() => {
        window.location = this.state.redirectUrl
          ? this.state.redirectUrl
          : "/dashboard";
      })
      .catch(() => {
        this.setState({ errorMessage: "Invalid username or password" });
      });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Link
            href={`${API_ROOT}/accounts/strava/login/?process=login`}
            variant="body2"
          >
            <img
              src={StravaButton}
              alt="logo"
              className={classes.img}
              style={{ padding: "20px" }}
            />
          </Link>
          <Typography component="h5" variant="h6">
            OR
          </Typography>
          <form className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username or Email"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={this.handleChange("username")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handleChange("password")}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {this.state.errorMessage ? (
              <SnackbarContent
                message={this.state.errorMessage}
                color="danger"
              />
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submitForm}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="resetpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    );
  }
}

export default withStyles(signinStyle)(SignIn);
