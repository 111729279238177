/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";

import { MadeWithLove } from "components/MadeWithLove/MadeWithLove.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { forgotPassword, resetPassword } from "services/authProvider.js";

import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    };
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    if (this.state.acceptPassword !== true) {
      forgotPassword(this.state.email)
        .then(() => {
          this.setState({
            errorMessage: "Password reset link has been sent to you via email."
          });
        })
        .catch(() => {
          this.setState({ errorMessage: "No user found with this email." });
        });
    } else {
      resetPassword(
        this.state.uid,
        this.state.token,
        this.state.password,
        this.state.confirmPassword
      )
        .then(() => {
          this.setState({
            errorMessage: "Password reset success. You can login now."
          });
        })
        .catch(() => {
          this.setState({ errorMessage: "Something went wrong." });
        });
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  componentDidMount() {
    let params = this.props.match.params;
    if (params) {
      let uid = params.uid64;
      let token = params.token;
      if (uid !== undefined && token !== undefined) {
        this.setState({ acceptPassword: true, uid: uid, token: token });
      }
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          {this.state.acceptPassword !== true ? (
            <p>Confirm your email to receive reset password link.</p>
          ) : null}
          <form className={classes.form}>
            {this.state.acceptPassword ? (
              <div>
                <TextField
                  type="password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  autoFocus
                  onChange={this.handleChange("password")}
                />
                <TextField
                  type="password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm password"
                  name="confirmPassword"
                  onChange={this.handleChange("confirmPassword")}
                />
              </div>
            ) : (
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChange("email")}
              />
            )}
            {this.state.errorMessage ? (
              <SnackbarContent
                message={this.state.errorMessage}
                color="danger"
              />
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submitForm}
            >
              Submit
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/signin" variant="body2">
                  Sign In
                </Link>
              </Grid>
              <Grid item>
                <Link href="signup" variant="body2">
                  Sign Up
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    );
  }
}

export default withStyles(signinStyle)(ResetPassword);
