import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LoopIcon from "@material-ui/icons/Loop";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import SyncIcon from "@material-ui/icons/Sync";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import Button from "components/CustomButtons/Button.jsx";
import Collapse from "@material-ui/core/Collapse";

// core components
import tableStyle from "./styles.jsx";

function getSpeedClass(avgSpeed, speed) {
  if (avgSpeed <= 0) {
    avgSpeed = 1;
  }
  let speedClass = speed / avgSpeed;

  if (speedClass > 2) return "ultrahigh";
  if (speedClass > 1.5) return "veryhigh";
  if (speedClass > 1.2) return "higher";
  if (speedClass > 1) return "high";
  if (speedClass === 1) return "avg";
  if (speedClass < 0.5) return "slower";
  return "slow";
}

class ActivityDetailsTable extends React.Component {
  state = {
    value: 0,
    forms: [],
    activities: [],
    notes: {}
  };

  static getDerivedStateFromProps(props, state) {
    const { rowsData, showAllNotes } = props;
    const { lastShowAllNotes } = state;
    if (showAllNotes !== lastShowAllNotes) {
      let notesEnabled = {};
      rowsData &&
        rowsData.forEach((_, idx) => {
          notesEnabled[idx] = showAllNotes;
        });
      return { notesEnabled: notesEnabled, lastShowAllNotes: showAllNotes };
    }
  }

  toggleNote = rowIdx => {
    let notesEnabled = this.state.notesEnabled;
    notesEnabled[rowIdx] = !(notesEnabled[rowIdx] === true);
    this.setState({ notesEnabled: notesEnabled });
  };

  noteUpdated = rowIdx => e => {
    e.stopPropagation();
    const notes = this.state.notes;
    notes[rowIdx] = e.target.value;
    this.setState({ notes: notes });
  };

  getRowIcon = columnName => {
    const { classes } = this.props;
    switch (columnName) {
      case "lap_index":
        return <LoopIcon className={classes.rowDataIcon} />;
      case "avg_heart_rate":
        return <FavoriteBorderIcon className={classes.rowDataIcon} />;
      case "distance_meters":
        return <DirectionsRunIcon className={classes.rowDataIcon} />;
      case "duration_seconds":
        return <TimelapseIcon className={classes.rowDataIcon} />;
      case "average_pace":
        return <FlashOnIcon className={classes.rowDataIcon} />;
      case "stride_length":
        return <SwapHorizIcon className={classes.rowDataIcon} />;
      case "avg_run_cadence":
        return <SyncIcon className={classes.rowDataIcon} />;
      case "elevation_gain":
        return <ArrowUpwardIcon className={classes.rowDataIcon} />;
      default:
        return null;
    }
  };

  render() {
    const {
      classes,
      headersMap,
      rowsData,
      avgSpeed,
      tableHeaderColor,
      activityNotes,
      allowNotesEditing
    } = this.props;
    let headerKeys = Object.keys(headersMap);
    let rowKeys = headerKeys.map(key => headersMap[key]);
    // Add key for add note button
    rowKeys.splice(0, 0, "_add_note");
    return (
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {headersMap !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow>
                <TableCell />
                {headerKeys.map(key => {
                  return (
                    <TableCell
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {key}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {rowsData &&
              rowsData.map((rowData, idx) => {
                let speedClass = getSpeedClass(
                  avgSpeed,
                  rowData["average_speed"]
                );
                return (
                  <React.Fragment key={idx}>
                    <TableRow
                      key={idx}
                      className={classes.tableCell + " " + speedClass}
                    >
                      {rowKeys.map(key => {
                        if (key === "_add_note") {
                          return (
                            <IconButton
                              className={classes.addNoteIconButton}
                              key={`add-note-${key}`}
                              aria-label="Close"
                              color="inherit"
                              onClick={() => this.toggleNote(idx)}
                            >
                              {this.state.notesEnabled[idx] === true ? (
                                <ExpandMore
                                  className={classes.expandLessIcon}
                                />
                              ) : (
                                <ExpandLess
                                  className={classes.expandMoreIcon}
                                />
                              )}
                            </IconButton>
                          );
                        }
                        return (
                          <TableCell className={classes.tableCell} key={key}>
                            {this.getRowIcon(key)}
                            {rowData[key]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={this.state.notesEnabled[idx] === true}
                          timeout="auto"
                          unmountOnExit
                        >
                          {allowNotesEditing === true ? (
                            <div className={classes.notesAndActionContainer}>
                              <div className={classes.notesContainer}>
                                <textarea
                                  className="textarea"
                                  name="comment"
                                  placeholder="Add note"
                                  onChange={this.noteUpdated(idx)}
                                  defaultValue={
                                    activityNotes &&
                                    activityNotes[rowData["id"]]
                                  }
                                />
                              </div>
                              <div className={classes.notesActionContainer}>
                                <Button
                                  color="info"
                                  onClick={_ => {
                                    this.props.onNotesUpdate &&
                                      this.props.onNotesUpdate(
                                        rowData["id"],
                                        this.state.notes[idx]
                                      );
                                  }}
                                >
                                  <span className="material-icons">save</span>
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className={classes.notesContainer}>
                              {activityNotes &&
                                activityNotes[rowData["id"]] && (
                                  <div>
                                    <strong>Notes: </strong>
                                    <span>{activityNotes[rowData["id"]]}</span>
                                  </div>
                                )}
                            </div>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

ActivityDetailsTable.defaultProps = {
  tableHeaderColor: "gray"
};

ActivityDetailsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  avgSpeed: PropTypes.number,
  headersMap: PropTypes.any,
  rowsData: PropTypes.array,
  activityNotes: PropTypes.any,
  onNotesUpdate: PropTypes.func,
  showAllNotes: PropTypes.bool,
  allowNotesEditing: PropTypes.bool
};

export default withStyles(tableStyle)(ActivityDetailsTable);
