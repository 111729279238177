import React from "react";
import PropTypes from "prop-types";
import {
  Calendar as CalendarComponent,
  momentLocalizer
} from "react-big-calendar";

// import Datetime from "react-datetime";
// @material-ui/core
import Assignment from "@material-ui/icons/Assignment";
import withStyles from "@material-ui/core/styles/withStyles";
// import TextField from "@material-ui/core/TextField";

// import FormControl from "@material-ui/core/FormControl";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import CommentBox from "components/CommentBox/CommentBox.jsx";
import Comment from "components/Comment/Comment.jsx";

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import {
  getAnswers,
  updateAnswers,
  getUserForms,
  getMyUsers
} from "../../services/advisorUsers.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import "./QuizResponse.css";
import { dayjs } from "utils/time.jsx";

const localizer = momentLocalizer(dayjs);
const eventColors = event => {
  var backgroundColor = "event-";
  event.color
    ? (backgroundColor = backgroundColor + event.color)
    : (backgroundColor = backgroundColor + "default");
  return {
    className: backgroundColor
  };
};

class QuizResponse extends React.Component {
  state = {
    value: 0,
    users: []
  };

  ftechAnswers() {
    if (this.state.userId && this.state.formId) {
      getAnswers(
        // eslint-disable-next-line react/prop-types
        this.props.axios,
        this.state.userId,
        this.state.formId,
        this.state.day
      ).then(data => {
        this.setState({ answersData: data });
      });
    }
  }

  handleChange = name => value => {
    if (name === "user") {
      getUserForms(
        this.props.axios,
        value,
        true,
        this.state.startTime,
        this.state.endTime
      ).then(data => {
        this.setState({
          quizzes: data,
          userId: value,
          formId: undefined,
          day: undefined,
          answersData: undefined,
          startTime: undefined,
          endTime: undefined
        });
      });
    }
  };

  clanderTimeChanged = event => {
    let time = dayjs(event);
    let startTime = dayjs(
      `${time.format("YYYY")}-${time.format("MM")}-01`
    ).utc();
    let endTime = startTime.clone();
    endTime.add(30, "days");
    this.setState(
      { startTime: startTime.format(), endTime: endTime.format() },
      () => {
        getUserForms(
          this.props.axios,
          this.state.userId,
          true,
          this.state.startTime,
          this.state.endTime
        ).then(data => {
          this.setState({
            quizzes: data
          });
        });
      }
    );
  };

  handleEventSelection = event => {
    // console.log("handleEventSelection", event);
    this.setState(
      {
        formId: event.id,
        day: dayjs(event.start).format()
      },
      dt => this.ftechAnswers()
    );
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getMyUsers(this.props.axios).then(data => {
      this.setState({ users: data });
    });
  }

  handleCommentChange = idx => e => {
    let comments = this.state.comments || {};
    comments[idx] = e.target.value;
    this.setState({ comments: comments });
  };

  getAnswer(data) {
    let answer = "";
    data &&
      Object.keys(data).forEach(key => {
        let answerVal = data[key];
        if (key !== "feedback") {
          if (key === "-data") {
            answerVal = answerVal.replace("-data", "");
            let dateVal = dayjs(answerVal).format("LLL");
            if (dateVal !== "Invalid date") {
              answerVal = dateVal;
            }
          } else if (answerVal === true) {
            answerVal = key;
          } else {
            answerVal = `${key}: ${answerVal}`;
          }
          answer = `${answerVal}, ${answer}`;
        }
      });
    return answer;
  }

  addComment = answerId => (name, comment) => {
    let commentData = {
      answer_id: answerId,
      comment: comment
    };
    updateAnswers(
      this.props.axios,
      this.state.userId,
      this.state.formId,
      commentData
    ).then(data => {
      alert("Submitted Successfully");
      this.setState({ answersData: data });
    });
  };

  renderCalendar() {
    const quizzes = this.state.quizzes || [];
    const calendarEvents = quizzes.map(session => {
      return {
        id: session.form_id,
        title: session.form_name,
        start: new Date(session.date),
        end: new Date(session.date),
        color: "rose"
      };
    });
    let defaultDate = new Date();
    if (calendarEvents.length > 0) {
      defaultDate = calendarEvents[0].start;
    }
    // console.log("defaultDate", defaultDate);
    return (
      <Card>
        <CardBody calendar>
          <CalendarComponent
            selectable
            localizer={localizer}
            events={calendarEvents}
            defaultView="month"
            scrollToTime={new Date(2019, 1, 1, 6)}
            defaultDate={defaultDate}
            onSelectEvent={this.handleEventSelection}
            eventPropGetter={eventColors}
            onNavigate={this.clanderTimeChanged}
          />
        </CardBody>
      </Card>
    );
  }

  renderUsers() {
    const { classes } = this.props;
    let answers =
      (this.state.answersData && this.state.answersData.answers) || [];
    const formData = this.state.answersData && this.state.answersData.form;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>
            <CardBody>
              {this.renderInputs()}
              <h3>{formData && formData.name}</h3>
              <Table
                tableHeaderColor="primary"
                tableData={answers.map((answerData, idx) => {
                  return [this.renderComments(idx, answerData)];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderInputs() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText={"User"}
            items={userOptions}
            onChange={this.handleChange("user")}
          />
        </GridItem>
        {this.renderCalendar()}
      </GridContainer>
    );
  }

  renderComments(idx, answerData) {
    const answer = {
      user_name: "answer",
      comment: this.getAnswer(
        answerData.answer_details && answerData.answer_details.answer
      ),
      at: answerData.created_at
    };
    const comments =
      (answerData.answer_details && answerData.answer_details.comments) || [];
    return (
      <div>
        <h6 className="Question">{answerData.question.question}</h6>
        <Comment comment={answer} />
        {comments.map((comment, idx) => {
          // eslint-disable-next-line react/prop-types
          if (comment.user_name === this.props.user.user_name) {
            comment.user_name = "you";
          }
          return <Comment key={`comment-${idx}`} comment={comment} />;
        })}
        <CommentBox title={""} addComment={this.addComment(answerData.id)} />
      </div>
    );
  }

  render() {
    return <div>{this.renderUsers()}</div>;
  }
}

QuizResponse.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(QuizResponse);
