import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import Assignment from "@material-ui/icons/Assignment";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import CommentBox from "components/CommentBox/CommentBox.jsx";
import Comment from "components/Comment/Comment.jsx";

import { getAnswers, updateAnswers } from "../../services/advisorUsers.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

import "./QuizResponseUser.css";
import { dayjs } from "utils/time.jsx";

class QuizResponseUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.addComment = this.addComment.bind(this);
  }

  ftechAnswers() {
    if (this.state.formId) {
      getAnswers(
        // eslint-disable-next-line react/prop-types
        this.props.axios,
        "self",
        this.state.formId,
        this.state.day
      ).then(data => {
        this.setState({ answersData: data });
      });
    }
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const formId = this.props.match.params.quizName;
    getAnswers(this.props.axios, "self", formId).then(data => {
      this.setState({ answersData: data });
    });
  }

  addComment = answerId => (name, comment) => {
    const formId = this.props.match.params.quizName;
    let commentData = {
      answer_id: answerId,
      comment: comment
    };
    updateAnswers(this.props.axios, "self", formId, commentData).then(data => {
      alert("Submitted Successfully");
      this.setState({ answersData: data });
    });
  };

  getAnswer(data) {
    let answer = "";
    data &&
      Object.keys(data).forEach(key => {
        let answerVal = data[key];
        if (key !== "feedback" && key !== "user_input") {
          if (key === "-data") {
            answerVal = answerVal.replace("-data", "");
            let dateVal = dayjs(answerVal).format("LLL");
            if (dateVal !== "Invalid date") {
              answerVal = dateVal;
            }
          } else if (answerVal === true) {
            answerVal = key;
          } else {
            answerVal = `${key}: ${answerVal}`;
          }
          answer = `${answerVal}, ${answer}`;
        }
      });
    return answer;
  }

  renderUsers() {
    const { classes } = this.props;
    let answers =
      (this.state.answersData && this.state.answersData.answers) || [];
    const formData = this.state.answersData && this.state.answersData.form;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>
            <CardBody>
              <h3>{formData && formData.name}</h3>
              <Table
                tableHeaderColor="primary"
                tableData={answers.map((answerData, idx) => {
                  return [this.renderComments(idx, answerData)];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderComments(idx, answerData) {
    const answer = {
      user_name: "you",
      comment: this.getAnswer(
        answerData.answer_details && answerData.answer_details.answer
      ),
      at: answerData.created_at
    };
    const comments =
      (answerData.answer_details && answerData.answer_details.comments) || [];
    return (
      <div>
        <h6 className="Question">{answerData.question.question}</h6>
        <Comment comment={answer} />
        {comments.map((comment, idx) => {
          if (comment.user_name === this.props.user.user_name) {
            comment.user_name = "you";
          }
          return <Comment key={`comment-${idx}`} comment={comment} />;
        })}
        <CommentBox title={""} addComment={this.addComment(answerData.id)} />
      </div>
    );
  }

  render() {
    return <div>{this.renderUsers()}</div>;
  }
}

QuizResponseUser.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(QuizResponseUser);
