import { API_ROOT } from "config.js";

const USER_WIDGETS_LIST_ENDPOINT = `${API_ROOT}/api/widgets/`;

export async function getUserWidgets(axios, userId = null) {
  let url = USER_WIDGETS_LIST_ENDPOINT;
  if (userId != null) {
    url = `${url}?userId=${userId}`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
