import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import Assignment from "@material-ui/icons/Assignment";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { getMyUsers } from "../../services/advisorUsers.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class MyUsers extends React.Component {
  state = {
    value: 0,
    users: []
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getMyUsers(this.props.axios).then(data => {
      this.setState({ users: data });
    });
  }

  handleClick = (name, idx) => e => {
    if (name === "dashboard") {
      this.props.history.push(`/trainee-dashboard/${idx}`);
    } else if (name === "chat") {
      this.props.history.push(`/chat/${idx}`);
    }
  };

  renderUsers() {
    const { classes } = this.props;
    let users = this.state.users;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["User", "Weekly Training Score", ""]}
                tableData={users.map((userData, idx) => {
                  let userName = `${userData.first_name} ${userData.last_name}`;
                  let userTrainingProgress =
                    userData.preferences &&
                    userData.preferences.training_progress;
                  if (userTrainingProgress) {
                    userTrainingProgress = `${Math.round(
                      userTrainingProgress,
                      1
                    )} %`;
                  } else {
                    userTrainingProgress = "--";
                  }
                  if (userName.trim() === "") {
                    userName = userData.username;
                  }
                  return [
                    <details key={`user-details-${idx}`}>
                      <summary>
                        <strong>{userName}</strong>
                      </summary>
                      <p>
                        <strong>Email:</strong> {userData.email}
                        <br />
                        <strong>City:</strong> {userData.city}
                        <br />
                        <Button
                          size="sm"
                          color={"success"}
                          key={`chat-button-${idx}`}
                          onClick={this.handleClick("chat", userData.username)}
                        >
                          Send Message
                        </Button>
                      </p>
                    </details>,
                    <div key={`user-score-${idx}`} className="userScore">
                      {userTrainingProgress}
                    </div>,
                    <Button
                      size="sm"
                      color={"success"}
                      key={`dashboard-button-${idx}`}
                      onClick={this.handleClick("dashboard", userData.username)}
                    >
                      Go to Dashboard
                    </Button>
                  ];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    return <div>{this.renderUsers()}</div>;
  }
}

MyUsers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(MyUsers);
