import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/css/material-dashboard-react.css?v=1.5.0";

import indexRoutes from "routes/index.jsx";

import { SENTRY_DSN } from "config.js";

import "init-firebase.js";

const hist = createBrowserHistory();

Sentry.init({ dsn: SENTRY_DSN });

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </Router>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  // Register a service worker hosted at the root of the site using the default scope.
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then(
      registration => {
        // console.log("Service worker registration succeeded:", registration);
      },
      /*catch*/ error => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
} else {
  console.error("Service workers are not supported.");
}
