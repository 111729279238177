/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import { DATE_DISPLAY_FORMAT_DETAILED, dayjs } from "utils/time.jsx";
import { getActivities } from "services/userDataProvider.js";
import { successColor } from "assets/jss/material-dashboard-react.jsx";
import { getMyUsers } from "services/advisorUsers.js";
import {
  STRAVA_ACTIVITY_DETAILS_PATH,
  GARMIN_ACTIVITY_DETAILS_PATH
} from "config.js";

const activityStyles = {
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  cardCategory: {
    color: "#999999",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    marginTop: "0px",
    minHeight: "auto",
    fontSize: "initial",
    fontWeight: "500",
    color: "#1729cf",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardSubtitle: {
    color: "#1729cf"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  userActivities: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  userActivity: {
    width: "350px"
  },
  activityDetails: {
    display: "flex",
    flexDirection: "column",
    height: "128px"
  },
  activityKeyDetailsDistance: {
    fontSize: "larger"
    // color: "crimson"
  },
  activityKeyDetailsDistanceValue: {
    color: "crimson"
  },
  userInfo: {
    color: "blue"
  },
  activityDetailsLinksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end"
  },
  activityDetailsLink: {
    color: "#FC4C02"
  }
};

class Dashboard extends React.Component {
  state = {
    value: 0,
    forms: [],
    activities: []
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleUserChange = value => {
    this.setState(
      {
        userId: value,
        activities: []
      },
      () => {
        let user = this.props.user;
        let platform = "";
        let fetchTrainees = false;
        if (user) {
          fetchTrainees = user.roles && user.roles.includes("ADVISOR");
          platform = user && user.preferences && user.preferences.platform;
        }
        getActivities(this.props.axios, value, platform, fetchTrainees).then(
          data => this.setState({ activities: data.results })
        );
      }
    );
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    let user = this.props.user;
    let platform = "";
    let fetchTrainees = false;
    if (user) {
      fetchTrainees = user.roles && user.roles.includes("ADVISOR");
      platform = user && user.preferences && user.preferences.platform;
    }
    if (fetchTrainees) {
      getMyUsers(this.props.axios).then(data => {
        this.setState({
          users: data
        });
      });
    }
    getActivities(this.props.axios, null, platform, fetchTrainees).then(data =>
      this.setState({ activities: data.results })
    );
  }

  renderUserData(userDetails) {
    const { classes } = this.props;
    if (!userDetails) return null;
    return (
      <div className={classes.userInfo}>
        <strong>{userDetails.first_name}</strong>{" "}
        <strong>{userDetails.last_name}</strong>
      </div>
    );
  }

  renderUserActivity(activityData) {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    let activityIcon = <DirectionsRun />;
    let activity_type = activityData.activity_type.toLowerCase();
    let userDetails = activityData.user;

    if (activity_type.includes("ride")) {
      activityIcon = <DirectionsBike />;
    } else if (activity_type.includes("weight")) {
      activityIcon = <FitnessCenter />;
    }
    const activityDetailsPath =
      activityData.platform === "strava"
        ? STRAVA_ACTIVITY_DETAILS_PATH
        : GARMIN_ACTIVITY_DETAILS_PATH;
    return (
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">{activityIcon}</CardIcon>
          <h3 className={classes.cardTitle}>{activityData.activity_name}</h3>
          <p className={classes.cardSubtitle}>
            {dayjs(activityData.start_time_gmt).format(
              DATE_DISPLAY_FORMAT_DETAILED
            )}
          </p>
        </CardHeader>
        <CardBody className={classes.activityDetails}>
          <div className={classes.activityKeyDetailsDistance}>
            {this.renderUserData(userDetails)}
            <strong>Total Distance</strong>{" "}
            <span className={classes.activityKeyDetailsDistanceValue}>
              {(activityData.distance_meters / 1000).toFixed(1)} kms
              {activityData.activity_plan &&
                activityData.activity_plan.status &&
                `, ${activityData.activity_plan.status}`}
            </span>
          </div>
          <div>
            <strong>Total Time</strong>{" "}
            {(activityData.duration_seconds / 60).toFixed(1)} minutes
            <br />
            {activityData.aerobic_training_effect != null &&
              activityData.anaerobic_training_effect != null && (
                <div className={classes.trainingEffect}>
                  <strong>Training Effect</strong>{" "}
                  {`${activityData.aerobic_training_effect.toFixed(
                    1
                  )}, ${activityData.anaerobic_training_effect.toFixed(1)}`}
                </div>
              )}
            {activityData.vO2_max_value != null && (
              <div className={classes.vO2Max}>
                <strong>VO2 Max</strong> {activityData.vO2_max_value}
              </div>
            )}
            <strong>Average Heart Rate</strong> {activityData.avg_heart_rate}{" "}
            <br />
            <strong>Average Speed</strong>{" "}
            {(activityData.average_speed * 3.6).toFixed(2)} kms/hr
          </div>
          <br />
        </CardBody>
        <CardFooter stats>
          <Button
            color="info"
            size="sm"
            className={classes.marginRight}
            onClick={e =>
              this.props.history.push(
                `activity-details/${activityData.activity}`
              )
            }
          >
            Details
          </Button>
          <div className={classes.activityDetailsLinksContainer}>
            <a
              className={classes.activityDetailsLink}
              target="_blank"
              href={`${activityDetailsPath}/${activityData.source_activity_id}`}
            >
              {activityData.platform === "strava"
                ? "View on Strava"
                : "View on Garmin"}
            </a>
          </div>
        </CardFooter>
      </Card>
    );
  }

  renderUserActivities() {
    const { classes } = this.props;
    let activities = this.state.activities ? this.state.activities.length : 0;
    let rows = [];
    for (let i = 0; i < activities; i += 1) {
      let activityData = this.state.activities && this.state.activities[i];
      if (activityData) {
        rows.push(
          <div
            className={classes.userActivity}
            xs={12}
            sm={6}
            md={6}
            lg={3}
            style={{ marginRight: "10px" }}
            key={`activity-${activityData.activity}`}
          >
            {this.renderUserActivity(activityData)}
          </div>
        );
      }
    }
    return <div className={classes.userActivities}>{rows}</div>;
  }

  renderUsersSelectionBox() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText={"User"}
            items={userOptions}
            onChange={this.handleUserChange}
          />
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes, user } = this.props;
    const isAdvisor = user.roles && user.roles.includes("ADVISOR");
    return (
      <div>
        {isAdvisor ? this.renderUsersSelectionBox() : null}
        {this.renderUserActivities()}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(activityStyles)(Dashboard);
