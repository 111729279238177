import React from "react";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button.jsx";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { withAxios } from "containers/authenticated.js";
import { logout } from "services/authProvider.js";
import {
  getNotifications,
  // readNotification
} from "services/userDataProvider.js";

class HeaderLinks extends React.Component {
  state = {
    notifications: false,
    user: false,
    userNotifications: []
  };

  componentDidMount() {
    getNotifications(this.props.axios).then(data =>
      this.setState({ userNotifications: data.results })
    );
  }

  handleToggle = name => () => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  handleClose = notificationId => event => {
    this.setState({ notifications: false, user: false });
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    if (notificationId) {
      // readNotification(this.props.axios, notificationId).then(data => {
      //   console.log("read notification ", notificationId);
      // });
      this.props.history.push("/dashboard");
    }
  };

  // eslint-disable-next-line no-unused-vars
  logout = event => {
    logout();
    this.props.history.push("/start");
  };

  render() {
    const { classes } = this.props;
    const { notifications, user } = this.state;
    return (
      <div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={notifications ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle("notifications")}
            className={classes.buttonLink}
          >
            <Notifications className={classes.icons} />
            {this.state.userNotifications && this.state.userNotifications.length > 0 ? (
              <span className={classes.notifications}>
                {this.state.userNotifications.length}
              </span>
            ) : null}
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Notifications
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={notifications}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !notifications }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose(null)}>
                    <MenuList role="menu">
                      {this.state.userNotifications.map((notif, idx) => {
                        return (
                          <MenuItem
                            onClick={this.handleClose(notif.id)}
                            className={classes.dropdownItem}
                            key={`notification-${idx}`}
                          >
                            {notif.verb}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
        <div className={classes.manager}>
          <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={user ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle("user")}
            className={classes.buttonLink}
          >
            <Person className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Account
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={user}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !user }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.logout}
                        className={classes.dropdownItem}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(withAxios(HeaderLinks));
