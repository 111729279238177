import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";
import { onMessage, getToken } from "firebase/messaging";
import { registerUserDevice } from "services/userDataProvider.js";

const firebaseConfig = {
  apiKey: "AIzaSyAHyZ-_vBMW6t1Sm2JCdjIpXPYX2YBeNfE",
  authDomain: "runnboard.firebaseapp.com",
  projectId: "runnboard",
  storageBucket: "runnboard.appspot.com",
  messagingSenderId: "615138681693",
  appId: "1:615138681693:web:af7283a7934bee9f307eb4"
};

export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const SetupNotification = (axios) => {
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BHJ0TieVQJYcUPwrmj8a-TtcsRNfmbg9l3V2Io4RgCYe47RB_sGDyji7fUfEv2xtIYUpOpA307kfTKtzWAc6_ho"
      })
        .then(currentToken => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            registerUserDevice(axios, currentToken);
          } else {
            // Show permission request UI
            console.log("No registration token available. Request permission to generate one.");
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  });
};

onMessage(messaging, payload => {
  console.log("Message received. ", payload);
});
