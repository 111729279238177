export function kmsPerHourToMinutesPerKm(kmsPerHour) {
  let secondsPerKm = 3600 / kmsPerHour;
  let minutes = parseInt(secondsPerKm / 60);
  let seconds = (secondsPerKm % 60).toFixed(0);
  return `${minutes}:${seconds.padStart(2, 0)}`;
}

export function metersPerSecondToMinutesPerKm(metersPerSecond) {
  let kmsPerHour = metersPerSecond * 3.6;
  let secondsPerKm = 3600 / kmsPerHour;
  let minutes = parseInt(secondsPerKm / 60);
  let seconds = (secondsPerKm % 60).toFixed(0);
  return `${minutes}:${seconds.padStart(2, 0)}`;
}

export function secondsToTime(seconds) {
  const hours = parseInt(seconds / 3600);
  seconds = seconds - hours * 3600;
  let minutes = parseInt(seconds / 60);
  seconds = seconds - minutes * 60;
  minutes = minutes.toFixed(0).padStart(2, 0);
  seconds = seconds.toFixed(0).padStart(2, 0);
  if (hours === 0) {
    return `${minutes}:${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}

export function fixCadence(platform, activityType, cadence) {
  if (platform === "strava" && activityType.toLowerCase().includes("run")) {
    return cadence * 2;
  }
  return cadence;
}
