import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { dayjs } from "utils/time.jsx";
import { getMyUsers, generateUserTrainingPlan } from "services/advisorUsers.js";
import { getHealthMetricsData } from "services/userDataProvider.js";

const styles = {};

const PLAN_TYPE_OPTIONS = [
  { text: "Running 5W Cycle", value: "RUNNING_5W_CYCLE" },
  { text: "Cycling 5W Cycle", value: "CYCLING_5W_CYCLE" }
];

const YES_NO_OPTIONS = [
  { text: "Yes", value: true },
  { text: "No", value: false }
];

class TrainingsPlanner extends React.Component {
  state = {
    userId: null,
    targetDistance: 5,
    targetDate: null,
    trainingStartDate: null,
    planTargetMileage: 30,
    currentVdot: 50,
    lastFourWeeksMileage: 20,
    easyRunsPerWeek: 1,
    vdotPacesFor1k: "4:50,4:30,4:00,3:50,3:30",
    addCircuitTraining: "no",
    softStart: "no",
    lastSessionMileage: 20,
    trainingBreakDays: 10,
    currentMaxHr: 180,
    planType: "Running 5W Cycle",
    expectedRaceVdot: 50,
    addToUserCalendar: false,
    overrideExistingTrainingEvents: false,
    trainingName: ""
  };

  // eslint-disable-next-line no-unused-vars
  generatePlan = _e => {
    let payload = JSON.parse(JSON.stringify(this.state));
    payload["vdotPacesFor1k"] = payload["vdotPacesFor1k"].split(",");
    payload["targetDate"] = dayjs(payload["targetDate"]).format("YYYY-MM-DD");
    payload["trainingStartDate"] = dayjs(payload["trainingStartDate"]).format(
      "YYYY-MM-DD"
    );
    payload["targetDistance"] = parseInt(payload["targetDistance"]);
    payload["planTargetMileage"] = parseInt(payload["planTargetMileage"]);
    payload["currentVdot"] = parseInt(payload["currentVdot"]);
    payload["lastFourWeeksMileage"] = parseInt(payload["lastFourWeeksMileage"]);
    payload["easyRunsPerWeek"] = parseInt(payload["easyRunsPerWeek"]);
    payload["lastSessionMileage"] = parseInt(payload["lastSessionMileage"]);
    payload["expectedRaceVdot"] = parseInt(payload["expectedRaceVdot"]);
    payload["trainingBreakDays"] = parseInt(payload["trainingBreakDays"]);
    generateUserTrainingPlan(this.props.axios, payload)
      .then(data => {
        let csvContent = data.planText;
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8,"
        });
        const objUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", objUrl);
        link.setAttribute("download", "training_plan.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log("TP err", err);
      });
  };

  // eslint-disable-next-line no-unused-vars
  clearInputs = e => {
    this.setState({
      targetDistance: 5,
      targetDate: null,
      trainingStartDate: null,
      planTargetMileage: 30,
      currentVdot: 50,
      lastFourWeeksMileage: 20,
      easyRunsPerWeek: 1,
      vdotPacesFor1k: "4:50,4:30,4:00,3:50,3:30",
      addCircuitTraining: "no",
      softStart: "no",
      lastSessionMileage: 20,
      trainingBreakDays: 10,
      currentMaxHr: 180,
      planType: "Running 5W Cycle",
      expectedRaceVdot: 50,
      addToUserCalendar: false,
      overrideExistingTrainingEvents: false,
      trainingName: ""
    });
  };

  componentDidMount() {
    getMyUsers(this.props.axios).then(data => {
      this.setState({
        users: data
      });
    });
  }

  onChange = name => e => {
    this.setState({ [name]: e.target.value });
  };

  selectionChange = name => value => {
    this.setState({
      [name]: value
    });
  };

  handleUserChange = value => {
    getHealthMetricsData(this.props.axios, value).then(data => {
      let trainingPaces = "";
      let currentVdot = data.vo2_max || "";
      if (data && data.other_data && data.other_data.vdotTrainingPaces) {
        let vdotTrainingPaces = data.other_data.vdotTrainingPaces;
        trainingPaces = `${vdotTrainingPaces["easy"]},${
          vdotTrainingPaces["marathon"]
        },${vdotTrainingPaces["threshold"]},${vdotTrainingPaces["interval"]},${
          vdotTrainingPaces["repetition"]
        }`;
      }
      this.setState({
        currentVdot: currentVdot,
        userId: value,
        vdotPacesFor1k: trainingPaces
      });
    });
  };

  renderUsersSelectionBox() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText={"User"}
            items={userOptions}
            onChange={this.handleUserChange}
          />
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const yesNoOptions = [
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" }
    ];
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>
                  Trainings plan generator
                </h4>
                <p className={classes.cardCategoryWhite}>
                  Create a plan for your next race!
                  <br />
                  <a
                    href="https://vdoto2.com/calculator/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    VDOT Calculator
                  </a>
                </p>
              </CardHeader>
              <CardBody>
                {this.renderUsersSelectionBox()}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Target Race Distance(KMs)"
                      id="targetDistance"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["targetDistance"],
                        type: "number",
                        onChange: this.onChange("targetDistance")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Target Time(HH:MM:SS)"
                      id="targetRaceTime"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["targetRaceTime"],
                        onChange: this.onChange("targetRaceTime")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={this.state["trainingStartDate"] === "success"}
                      error={this.state["trainingStartDate"] === "error"}
                      labelText={
                        <span>
                          {"Trainings Start Date"}
                          <small>(required)</small>
                        </span>
                      }
                      id="trainingStartDate"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "date",
                        value: this.state["trainingStartDate"] || new Date(),
                        onChange: this.onChange("trainingStartDate")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={this.state["targetDate"] === "success"}
                      error={this.state["targetDate"] === "error"}
                      labelText={
                        <span>
                          {"Target Race Date"}
                          <small>(required)</small>
                        </span>
                      }
                      id="targetDate"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "date",
                        value: this.state["targetDate"] || new Date(),
                        onChange: this.onChange("targetDate")
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Max mileage per week(KMs)"
                      id="planTargetMileage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["planTargetMileage"],
                        type: "number",
                        onChange: this.onChange("planTargetMileage")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Average weekly mileage for last four weeks(KMs)"
                      id="lastFourWeeksMileage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["lastFourWeeksMileage"],
                        type: "number",
                        onChange: this.onChange("lastFourWeeksMileage")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Current VDOT"
                      id="currentVdot"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["currentVdot"],
                        type: "number",
                        onChange: this.onChange("currentVdot")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Expected race VDOT"
                      id="expectedRaceVdot"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["expectedRaceVdot"],
                        type: "number",
                        onChange: this.onChange("expectedRaceVdot")
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Easy runs per week"
                      id="easyRunsPerWeek"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["easyRunsPerWeek"],
                        type: "number",
                        onChange: this.onChange("easyRunsPerWeek")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="VDOT (EMTIR) Paces for one kilometer"
                      id="vdotPacesFor1k"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["vdotPacesFor1k"],
                        onChange: this.onChange("vdotPacesFor1k")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Soft Start (yes/no)?"
                      id="softStart"
                      items={yesNoOptions}
                      onChange={() => this.selectionChange("softStart")}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Add circuit training (yes/no)?"
                      id="addCircuitTraining"
                      items={yesNoOptions}
                      onChange={() =>
                        this.selectionChange("addCircuitTraining")
                      }
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Number of days on training break"
                      id="trainingBreakDays"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["trainingBreakDays"] || 0,
                        type: "number",
                        onChange: this.onChange("trainingBreakDays")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Avg weekly mileage before the break(KMs)"
                      id="lastSessionMileage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["lastSessionMileage"],
                        type: "number",
                        onChange: this.onChange("lastSessionMileage")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Max HR"
                      id="currentMaxHr"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["currentMaxHr"] || 180,
                        type: "number",
                        onChange: this.onChange("currentMaxHr")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Plan Type"
                      items={PLAN_TYPE_OPTIONS}
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={this.state["planType"] || "Running 5W Cycle"}
                      onChange={this.selectionChange("planType")}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Training Plan Name"
                      id="trainingPlanName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state["trainingName"],
                        onChange: this.onChange("trainingName")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Add to Calendar?"
                      items={YES_NO_OPTIONS}
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={this.state["addToUserCalendar"] || "No"}
                      onChange={this.selectionChange("addToUserCalendar")}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomSelect
                      labelText="Override existing calendar events?"
                      items={YES_NO_OPTIONS}
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={
                        this.state["overrideExistingTrainingEvents"] || "No"
                      }
                      onChange={this.selectionChange(
                        "overrideExistingTrainingEvents"
                      )}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="button" color="gray" onClick={this.clearInputs}>
                  Clear
                </Button>
                <Button type="button" color="info" onClick={this.generatePlan}>
                  Generate Plan
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TrainingsPlanner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TrainingsPlanner);
