import React from "react";
import PropTypes from "prop-types";
// import moment from "moment";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Heading from "components/Heading/Heading.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { getMyUsers } from "../../services/advisorUsers.js";
import SupersetDashboard from "components/SupersetDashboard/SupersetDashboard.jsx";

const styles = {
  trainingsCalenderContainer: {
    padding: "0px !important"
  },
  trainingsCardHeader: {
    padding: "15px !important",
    marginTop: "-20px !important",
    borderRadius: "3px !important",
    background: "linear-gradient(60deg, #275b95, #00acc1) !important",
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4) !important"
  }
};

class TraineeDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const userName = this.props.match.params.userName;
    // const fromTime = moment()
    //   .clone()
    //   .startOf("month")
    //   .format(moment.defaultFormatUtc);
    // const toTime = moment()
    //   .clone()
    //   .endOf("month")
    //   .format(moment.defaultFormatUtc);

    getMyUsers(this.props.axios).then(data => {
      this.setState({
        users: data,
        userId: data.find(user => user.username === userName).id,
        selectedTrainee: data.find(user => user.username === userName)
      });
    });
  }

  handleUserChange = value => {
    // const fromTime = moment()
    //   .clone()
    //   .startOf("month")
    //   .format(moment.defaultFormatUtc);
    // const toTime = moment()
    //   .clone()
    //   .endOf("month")
    //   .format(moment.defaultFormatUtc);
    this.setState({
      userId: value,
      selectedTrainee: this.state.users.find(user => user.id === value)
    });
  };

  renderUsersSelectionBox() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    const selectedTraineeName =
      this.state.selectedTrainee && this.state.selectedTrainee.username;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText={selectedTraineeName || "Trainee"}
            items={userOptions}
            onChange={this.handleUserChange}
          />
        </GridItem>
      </GridContainer>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars, react/prop-types
    const { classes, user } = this.props;
    const isAdvisor = user.roles && user.roles.includes("ADVISOR");
    const selectedTraineeName = this.state.selectedTrainee
      ? this.state.selectedTrainee.username
      : "";
    return (
      <div>
        <Heading
          textAlign="center"
          title="Trainee Dashboard"
          category={
            <span>{`Training Progress for ${selectedTraineeName}`}</span>
          }
        />
        {isAdvisor ? this.renderUsersSelectionBox() : null}
        <SupersetDashboard
          axios={this.props.axios}
          userId={this.state.userId}
        />
      </div>
    );
  }
}

TraineeDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TraineeDashboard);
