// import image from "assets/img/sdg-bg.png";
const appStyle = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
      // backgroundImage: "url(" + image + ")"
    }
  },
  footer: {
    color: "#eeeeee",
    textAlign: "left",
    margin: theme.spacing(1),
    fontSize: "10px",
    link: {
      color: "eeeeee"
    }
  },
  subtitle: {
    textAlign: "center",
    margin: theme.spacing(1),
    fontSize: "20px"
  },
  paper: {
    marginTop: theme.spacing(30),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  logo: {
    objectPosition: "50% 50%",
    width: "250px",
    objectFit: "cover"
  }
});

export default appStyle;
