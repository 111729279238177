import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";

import AddAlert from "@material-ui/icons/AddAlert";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import SupersetDashboard from "components/SupersetDashboard/SupersetDashboard.jsx";

import {
  getNotifications,
  readNotification
} from "services/userDataProvider.js";
// import { SetupNotification } from "init-firebase.js";

// import { MEDIA_ROOT } from "config.js";

const dashboardStyle = {
  ExternalDashboardContainer: {
    width: "100%"
  },
  ExternalDashboardTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "large",
    paddingTop: "25px",
    paddingBottom: "25px",
    display: "none"
  },
  ExternalDashboardROW: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "warp",
    width: "100%"
  },
  ExternalDashboardChart: {
    width: "100%"
  },
  ExternalDashboardChartTitle: {
    width: "100%",
    color: "#235894",
    fontSize: "large",
    paddingBottom: "25px"
  }
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgets: [],
      showNotificationsButton: false
    };
  }

  componentDidMount() {
    try {
      // SetupNotification(this.props.axios);
    } catch (err) {
      console.log("An error occurred while retrieving token. ", err);
    }
    // eslint-disable-next-line react/prop-types
    getNotifications(this.props.axios).then(data =>
      this.setState({ userNotifications: data.results })
    );
  }

  readNotification = notificationId => e => {
    // console.log("mark read", notificationId)
    readNotification(this.props.axios, notificationId).then(data => {
      this.setState({
        userNotifications: this.state.userNotifications.filter(
          notif => notif.id !== notificationId
        )
      });
    });
  };

  openNotification = notificationData => e => {
    readNotification(this.props.axios, notificationData.id);
    if (notificationData.data) {
      let data = notificationData.data;
      data.link && this.props.history.push(data.link);
    }
  };

  renderNotifications() {
    // console.log("user notifications", this.state.userNotifications);
    return (
      <GridContainer>
        {this.state.userNotifications &&
          this.state.userNotifications.map(notificationData => {
            return (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                key={`notification-${notificationData.id}`}
              >
                <SnackbarContent
                  message={notificationData.description}
                  icon={AddAlert}
                  color={notificationData.level}
                  open
                  openNotification={this.openNotification(notificationData)}
                  closeNotification={this.readNotification(notificationData.id)}
                  close
                />
              </GridItem>
            );
          })}
      </GridContainer>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <GridContainer xs={12} sm={12} md={12}>
        {this.renderNotifications()}
        <SupersetDashboard
          axios={this.props.axios}
          userId={this.props.user.user_id}
        />
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any
};

export default withStyles(dashboardStyle)(Dashboard);
