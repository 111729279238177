/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Datetime from "react-datetime";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
// import Check from "@material-ui/icons/Check";
// import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Primary from "components/Typography/Primary.jsx";

// import Quote from "components/Typography/Quote.jsx";

const styles = {};

class Question extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChange = (questionType, name, inputType) => event => {
    let value = null;
    let currentState = this.state;
    if (inputType === "checkbox") {
      value = event.target.checked;
    } else if (
      inputType === "date" ||
      inputType === "time" ||
      inputType === "datetime"
    ) {
      value = event;
    } else {
      value = event.target.value;
    }

    if (questionType.includes("single-select")) {
      Object.keys(currentState).forEach(key => {
        currentState[key] = false;
      });
      currentState[[name]] = value;
    } else {
      currentState[[name]] = value;
    }
    // this.setState({ ...this.state, [name]: value }, () => {
    this.setState(currentState, () => {
      if (this.props.onChange) {
        this.props.onChange(currentState);
      }
    });
  };

  render() {
    // const { classes } = this.props;
    const { question_id, question, question_type, options } = this.props.data;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {/* <Quote
                      text="I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at."
                      author=" Kanye West, Musician"
                    /> */}
            <Primary>{question}</Primary>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormGroup>
              {options.map((option, idx) => {
                let isChecked = this.state[option.name] === true;
                if (option.type === "boolean") {
                  return (
                    <FormControlLabel
                      key={`question-${question_id}-${idx}`}
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={this.handleChange(
                            question_type,
                            option.name,
                            "checkbox"
                          )}
                          value={option.name}
                        />
                      }
                      label={option.name}
                    />
                  );
                } else if (option.type === "boolean-with-text") {
                  return (
                    <div>
                      <FormControlLabel
                        key={`question-${question_id}-${idx}`}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={this.handleChange(
                              question_type,
                              option.name,
                              "checkbox"
                            )}
                            value={option.name}
                          />
                        }
                        label={option.name}
                      />
                      {this.state[option.name] ? (
                        <CustomInput
                          key={`question-${question_id}-${idx}`}
                          labelText={option.name}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: this.handleChange(
                              question_type,
                              `${option.name}-data`,
                              "text"
                            )
                          }}
                        />
                      ) : null}
                    </div>
                  );
                } else if (option.type === "text") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "text"
                        )
                      }}
                    />
                  );
                } else if (option.type === "email") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "email"
                        )
                      }}
                    />
                  );
                } else if (option.type === "number") {
                  return (
                    <CustomInput
                      key={`question-${question_id}-${idx}`}
                      labelText={option.name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "number"
                        )
                      }}
                    />
                  );
                } else if (option.type === "date") {
                  return (
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "date"
                        )}
                      />
                    </FormControl>
                  );
                } else if (option.type === "time") {
                  return (
                    <FormControl fullWidth>
                      <Datetime
                        dateFormat={false}
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "time"
                        )}
                      />
                    </FormControl>
                  );
                } else if (option.type === "datetime") {
                  return (
                    <FormControl fullWidth>
                      <Datetime
                        key={`question-${question_id}-${idx}`}
                        inputProps={{ placeholder: option.name }}
                        onChange={this.handleChange(
                          question_type,
                          `${option.name}-data`,
                          "datetime"
                        )}
                      />
                    </FormControl>
                  );
                }
                return null;
              })}
            </FormGroup>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Question);
