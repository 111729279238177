import React, { Component } from "react";
import { DATE_DISPLAY_FORMAT_DETAILED, dayjs } from "utils/time.jsx";

import "./Comment.css";

class Comment extends Component {
  render() {
    // console.log("Comment", this.props.comment);
    return (
      <article className="media">
        <figure className="media-left">
          <p className="image is-64x64">
            {/* <img
              src="https://bulma.io/images/placeholders/128x128.png"
              alt="Avatar"
            /> */}
          </p>
        </figure>
        <div className="media-content">
          <div className="content">
            <p>
              <strong className="comment-author">
                {this.props.comment.user_name}
              </strong>
              {this.props.comment.at && (
                <small className="comment-time">
                  {" "}
                  on{" "}
                  {dayjs(this.props.comment.at).format(
                    DATE_DISPLAY_FORMAT_DETAILED
                  )}
                  , {dayjs(this.props.comment.at).fromNow()}
                </small>
              )}
              <br />
              <span className="comment-text">{this.props.comment.comment}</span>
            </p>
          </div>
        </div>
      </article>
    );
  }
}

export default Comment;
