/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ContactSupport from "@material-ui/icons/ContactSupport";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import withStyles from "@material-ui/core/styles/withStyles";

import { MadeWithLove } from "components/MadeWithLove/MadeWithLove.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import signupStyle from "assets/jss/material-dashboard-react/layouts/signupStyle.jsx";

import { submitSupportRequest } from "services/authProvider.js";

class SupportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {},
      errorMessages: null
    };
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    submitSupportRequest(this.state.userData)
      .then(_ => {
        this.setState({
          userData: {},
          errorMessages: null,
          successMessage:
            "Your support request has been submitted! We will contact you soon."
        });
      })
      .catch(_ => {
        this.setState({
          errorMessages: [["Something went wrong! Please try again later."]]
        });
      });
  };

  handleChange = name => event => {
    let userData = this.state.userData;
    userData[name] = event.target.value;
    this.setState({ userData: userData });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <ContactSupport />
          </Avatar>
          <Typography component="h1" variant="h5">
            Contact Us
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={this.handleChange("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={10}
                  required
                  fullWidth
                  id="message"
                  label="Message"
                  name="message"
                  onChange={this.handleChange("message")}
                />
              </Grid>
              <Grid item xs={12}>
                {this.state.errorMessages &&
                  Object.keys(this.state.errorMessages).map(key => {
                    const messages = this.state.errorMessages[key];
                    return messages.map(message => {
                      return (
                        <SnackbarContent
                          message={message}
                          color="danger"
                          key={`error-${key}`}
                        />
                      );
                    });
                  })}
              </Grid>
              <Grid item xs={12}>
                {this.state.successMessage && (
                  <SnackbarContent
                    message={this.state.successMessage}
                    color="success"
                  />
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.submitForm}
            >
              Submit
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="signin" variant="body2">
                  Sign in
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    );
  }
}

export default withStyles(signupStyle)(SupportPage);
