/* eslint-disable react/prop-types */
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
// import { Doughnut } from "react-chartjs-2";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { getResults } from "../../services/quizDataProvider";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

let results = {
  detailed: null
};

class QuizResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: false
    };
  }

  // eslint-disable-next-line no-unused-vars
  handleClick = name => event => {};

  componentDidMount() {
    getResults(this.props.axios, this.props.match.params.quizName).then(
      data => {
        results["detailed"] =
          data.results[0]["metadata"] &&
          data.results[0]["metadata"]["detailed_result"];
        // data.results[0]["metadata"]["detailed_result"] = 0;
        this.setState({ updated: true, data: data.results[0] });
      }
    );
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const quizName = this.props.match.params.quizName;
    const data = this.state.data;
    const { classes } = this.props;

    const score = (data && data.score.toFixed(1)) || 0;
    const completed = (data && data.percentage_completion.toFixed(1)) || 0;
    const reportData = data && data.metadata && data.metadata.report;
    // console.log(data, reportData);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{quizName}</h4>
                <p
                  className={classes.cardCategoryWhite}
                >{`Completed: ${completed}% Score ${score}/100`}</p>
              </CardHeader>
              <CardBody>
                {reportData &&
                  reportData.map(data => {
                    const { type, value } = data;
                    if (type === "htmlText") {
                      return ReactHtmlParser(value);
                    } else if (type === "doughnut-chart") {
                      return (
                        <div>
                          {ReactHtmlParser(value.label)}
                          {/* <Doughnut data={value} /> */}
                        </div>
                      );
                    }
                    return [];
                  })}
              </CardBody>
              <CardFooter>
                <Button color="primary" onClick={this.handleClick("last")}>
                  Email Me
                </Button>
                <Button color="primary" onClick={this.handleClick("finish")}>
                  Finish
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(QuizResults);
