/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { DATE_DISPLAY_FORMAT_DETAILED, dayjs } from "utils/time.jsx";
import { getUserForms } from "../../services/quizDataProvider";
import {
  getNotifications,
  readNotification
} from "services/userDataProvider.js";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Dashboard extends React.Component {
  state = {
    value: 0,
    forms: []
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  readNotification = notificationId => e => {
    // console.log("mark read", notificationId)
    readNotification(this.props.axios, notificationId).then(data => {
      this.setState({
        userNotifications: this.state.userNotifications.filter(
          notif => notif.id !== notificationId
        )
      });
    });
  };

  openNotification = notificationData => e => {
    readNotification(this.props.axios, notificationData.id);
    if (notificationData.data) {
      let data = notificationData.data;
      data.link && this.props.history.push(data.link);
    }
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    getUserForms(this.props.axios).then(data =>
      this.setState({ forms: data.results })
    );
    getNotifications(this.props.axios).then(data =>
      this.setState({ userNotifications: data.results })
    );
  }

  renderUserForm(formData) {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" stats icon>
          <CardIcon color="warning">
            <Icon>content_copy</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>{formData.form.name}</p>
          <h3 className={classes.cardTitle}>
            {formData.score && formData.score.toFixed(1)} <small>%</small>
          </h3>
        </CardHeader>
        <CardBody>
          <GridContainer>
            {formData.metadata &&
              formData.metadata.scores &&
              formData.metadata.scores.map(socreData => {
                return (
                  <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    key={`score-${socreData.name}`}
                  >
                    <Chip
                      avatar={<Avatar>{socreData.value}</Avatar>}
                      label={socreData.name}
                      className={classes.chip}
                      variant="outlined"
                      color={socreData.status || "success"}
                    />
                  </GridItem>
                );
              })}
            Last Updated:{" "}
            {dayjs(formData.updated_at).format(DATE_DISPLAY_FORMAT_DETAILED)}
          </GridContainer>
          <p>{formData.form.description}</p>
          <div className={classes.stats} />
        </CardBody>
        <CardFooter stats>
          <Button
            color="info"
            size="sm"
            className={classes.marginRight}
            onClick={e =>
              this.props.history.push(`quizresponse/${formData.form.id}`)
            }
          >
            View Response
          </Button>
          <Button
            color="warning"
            size="sm"
            className={classes.btn}
            onClick={e =>
              this.props.history.push(`/quiz/${formData.form.name}`)
            }
          >
            Journal Entry
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderUserForms() {
    let forms = this.state.forms ? this.state.forms.length : 0;
    let rows = [];
    for (let i = 0; i < forms; i += 1) {
      let formData = this.state.forms && this.state.forms[i];
      if (formData && formData.form.is_journal === true) {
        rows.push(
          <GridItem
            xs={12}
            sm={6}
            md={6}
            lg={3}
            style={{ marginRight: "10px" }}
            key={`quiz-${formData.id}`}
          >
            {this.renderUserForm(formData)}
          </GridItem>
        );
      }
    }
    return <GridContainer>{rows}</GridContainer>;
  }

  renderNotifications() {
    // console.log("user notifications", this.state.userNotifications);
    return (
      <GridContainer>
        {this.state.userNotifications &&
          this.state.userNotifications.map(notificationData => {
            return (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                key={`notification-${notificationData.id}`}
              >
                <SnackbarContent
                  message={notificationData.description}
                  icon={AddAlert}
                  color={notificationData.level}
                  open
                  openNotification={this.openNotification(notificationData)}
                  closeNotification={this.readNotification(notificationData.id)}
                  close
                />
              </GridItem>
            );
          })}
      </GridContainer>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <div>
        {this.renderNotifications()}
        {this.renderUserForms()}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
