import logo from "assets/img/data-explorer.png";
// import image from "assets/img/sdg-bg.png";

export const staticData = {
  MadeWithLove: {
    orgTitle: "RunnBoard",
    orgLink: "https://okosengineering.com/",
    tagline: "Built by the "
  },
  company: {
    name: "RunnBoard",
    logo: logo,
    logoText: "RunnBoard",
    logoURL: "",
    url: "",
    tagline: ""
  },
  theme: {
    sideBarBackgroundImage: null
  }
};

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
// export const API_ROOT = "http://localhost:8001";
// export const MEDIA_ROOT = "http://localhost";
// export const WEB_SOCKET_API_PATH = "ws://localhost:8002/ws/chat";
// export const MEDIA_ROOT = "";
// export const API_ROOT = "";

export const STRAVA_ACTIVITY_DETAILS_PATH = "https://www.strava.com/activities";
export const GARMIN_ACTIVITY_DETAILS_PATH = "https://connect.garmin.com/modern/activity";
export const MEDIA_ROOT = "https://runnboard-api.okosengineering.com";
export const API_ROOT = "https://runnboard-api.okosengineering.com";
export const WEB_SOCKET_API_PATH =
  "wss://runnboard-api.okosengineering.com/ws/chat";
