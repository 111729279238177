/* eslint-disable react/prop-types */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@material-ui/core/Container";

import { MadeWithLove } from "components/MadeWithLove/MadeWithLove.jsx";
import { setAccessToken } from "containers/authenticated.js";
import { verifyLogin } from "services/authProvider.js";
// import { createGuest, verifyLogin } from "services/authProvider.js";

import signinStyle from "assets/jss/material-dashboard-react/layouts/signinStyle.jsx";

import { staticData } from "config.js";

class StartPage extends Component {
  static getDerivedStateFromProps(props, state) {
    let searchPath = props.location && props.location.search;
    if (searchPath) {
      let tempList = searchPath.split("&");
      let token = tempList.length > 1 ? tempList[1] : null;
      token = token.replace("token=", "");
      // console.log("token", token)
      if (token) {
        setAccessToken({ token: token });
        verifyLogin().then(data => {
          props.history.push("/dashboard");
        });
      }
    }
    return state;
  }

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null
    };

    this.submitForm = this.submitForm.bind();
  }
  // eslint-disable-next-line no-unused-vars
  submitForm = e => {
    e.preventDefault();
    // createGuest()
    //   .then(data => {
    //     this.props.history.push("/quiz/Dosha%20Quiz");
    //   })
    //   .catch(error => {
    //     this.setState({ errorMessage: "Something went wrong" });
    //   });
    this.props.history.push("/signup");
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            alt="logo"
            className={classes.logo}
            src={staticData.company.logoURL}
          />
          <Typography component="h3" variant="h5">
            Track your run progress
          </Typography>
          <Typography component="h5" variant="h7">
            with Runnboard
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={this.submitForm}
          >
            Start Now
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="signin" variant="body2">
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
            <Grid item>
              <Link href="signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    );
  }
}

export default withStyles(signinStyle)(StartPage);
