// @material-ui/icons
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Reorder from "@material-ui/icons/Reorder";
import Dashboard from "@material-ui/icons/Dashboard";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
import Person from "@material-ui/icons/Person";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import ColorizeIcon from '@material-ui/icons/Colorize';
import Chat from "@material-ui/icons/Chat";
import PeopleIcon from '@material-ui/icons/People';
import TodayIcon from '@material-ui/icons/Today';
// import ContentPaste from "@material-ui/icons/ContentPaste";
// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import JournalsPage from "views/Journals/Journals.jsx";
import ActivitiesPage from "views/Activities/ActivitiesPage.jsx";
import ActivityDetailsPage from "views/Activities/ActivityDetails.jsx";
import PaceCalculator from "views/PaceCalculator/PaceCalculator.jsx";
import TrainingsPlanner from "views/TrainingsPlanner/TrainingsPlanner.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import ChatPage from "views/ChatPage/ChatPage.jsx";
import CalendarPage from "views/Calendar/Calendar.jsx";
import TraineeDashboard from "views/TraineeDashboard/TraineeDashboard.jsx";
// import AppointmentPage from "views/BookAppointment/BookAppointment.jsx";
import MyUsers from "views/MyUsers/MyUsers.jsx";
import PlanPage from "views/Plan/Plan.jsx";
import QuizResponse from "views/QuizResponse/QuizResponse.jsx";
import QuizList from "views/QuizListing/QuizListing.jsx";

import { withAxios } from "containers/authenticated.js";

const advisorDashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: withAxios(DashboardPage)
  },
  {
    path: "/activities",
    sidebarName: "Activities",
    navbarName: "Activities",
    icon: FitnessCenter,
    component: withAxios(ActivitiesPage)
  },
  {
    path: "/activity-details/:activityId",
    sidebarName: "Activities",
    navbarName: "Activities",
    icon: FitnessCenter,
    component: withAxios(ActivityDetailsPage),
    hide: true
  },
  {
    path: "/mysessions",
    sidebarName: "Training Calendar",
    navbarName: "Training Calendar",
    icon: TodayIcon,
    component: withAxios(CalendarPage)
  },
  {
    path: "/my-trainees",
    sidebarName: "My Trainees",
    navbarName: "My Trainees",
    icon: PeopleIcon,
    component: withAxios(MyUsers)
  },
  {
    path: "/trainee-dashboard/:userName",
    sidebarName: "Trainee Dashboard",
    navbarName: "Trainee Dashboard",
    component: withAxios(TraineeDashboard),
    hide: true
  },
  {
    path: "/chat/:user",
    sidebarName: "Chat",
    navbarName: "Chat",
    icon: Chat,
    component: withAxios(ChatPage),
    hide: true
  },
  {
    path: "/health-plan/:user_id",
    sidebarName: "User health plans",
    navbarName: "User health plans",
    icon: Reorder,
    component: withAxios(PlanPage)
  },
  {
    path: "/pace-calculator",
    sidebarName: "Pace Calculator",
    navbarName: "Pace-Calculator",
    icon: DirectionsRun,
    component: withAxios(PaceCalculator)
  },
  {
    path: "/training-planner",
    sidebarName: "Trainings Planner",
    navbarName: "Trainings Planner",
    icon: ColorizeIcon,
    component: withAxios(TrainingsPlanner)
  },
  {
    path: "/journals",
    sidebarName: "Journals",
    navbarName: "Daily Journals",
    icon: NoteAddIcon,
    component: withAxios(JournalsPage)
  },
  {
    path: "/quiz/:quizName",
    sidebarName: "Quiz",
    navbarName: "Quiz",
    icon: QuestionAnswer,
    component: withAxios(QuizList),
    hide: true
  },
  {
    path: "/quizresponse/",
    sidebarName: "Quiz Responses",
    navbarName: "Quiz Responses",
    icon: QuestionAnswer,
    component: withAxios(QuizResponse)
  },
  {
    path: "/user",
    sidebarName: "About Me",
    navbarName: "About Me",
    icon: Person,
    component: withAxios(UserProfile)
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default advisorDashboardRoutes;
