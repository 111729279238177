/* eslint-disable react/prop-types */
import React, { Component } from "react";

import WebSocketInstance from "../../services/WebSocket";

import "./Chat.css";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      connectionInitiated: false
    };
    this.socketInitializor = setInterval(
      this.initiateConnection(props.currentUser, props.targetRoom),
      100
    );
    this.waitForSocketConnection(() => {
      WebSocketInstance.initChatUser(props.currentUser);
      WebSocketInstance.addCallbacks(
        this.setMessages.bind(this),
        this.addMessage.bind(this)
      );
      if (props.currentUser !== props.targetRoom) {
        WebSocketInstance.fetchMessages(props.targetRoom);
      } else {
        WebSocketInstance.fetchMessages(props.currentUser);
      }
    });
  }

  initiateConnection = (username, room) => {
    if (username !== undefined) {
      WebSocketInstance.connect(
        username,
        room
      );
      clearInterval(this.socketInitializor);
    }
  };

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(function() {
      // Check if websocket state is OPEN
      if (WebSocketInstance.state() === 1) {
        // console.log("Connection is made");
        callback();
        return;
      } else {
        // console.log("wait for connection...");
        component.waitForSocketConnection(callback);
      }
    }, 500); // wait 500 milisecond for the connection...
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    const chat = this.messagesEnd;
    const scrollHeight = chat.scrollHeight;
    const height = chat.clientHeight;
    const maxScrollTop = scrollHeight - height;
    chat.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  addMessage(message) {
    this.setState({ messages: [...this.state.messages, message] });
  }

  setMessages(messages) {
    this.setState({ messages: messages.reverse() });
  }

  messageChangeHandler = event => {
    this.setState({
      message: event.target.value
    });
  };

  sendMessageHandler = (e, message) => {
    const messageObject = {
      from: this.props.currentUser,
      message: message
    };
    WebSocketInstance.newChatMessage(messageObject);
    this.setState({
      message: ""
    });
    e.preventDefault();
  };

  renderMessages = messages => {
    const currentUser = this.props.currentUser;
    return messages.map((message, i) => (
      <li
        key={message.id}
        className={message.author === currentUser ? "me" : "him"}
      >
        {" "}
        <h4 className="author">{message.author} </h4>
        <p>{message.content}</p>
      </li>
    ));
  };

  render() {
    const messages = this.state.messages;
    return (
      <div className="chat">
        <div className="container">
          <h6>Displaying only the last 50 messages</h6>
          <ul
            ref={el => {
              this.messagesEnd = el;
            }}
          >
            {messages && this.renderMessages(messages)}
          </ul>
        </div>
        <div className="container message-form">
          <form
            onSubmit={e => this.sendMessageHandler(e, this.state.message)}
            className="form"
          >
            <input
              type="text"
              onChange={this.messageChangeHandler}
              value={this.state.message}
              placeholder="Type a Message"
              required
            />
            <button className="submit" type="submit" value="Submit">
              >
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Chat;
