import React, { Component } from "react";
import axios from "axios";
import { BrowserRouter, Switch } from "react-router-dom";

import { verifyLogin } from "services/authProvider";
import { API_ROOT } from "config.js";

const ACCESS_TOKEN_KEY = "access-token";

export const AuthenticatedContext = React.createContext(null);
class AuthenticatedComponent extends Component {
  constructor(props) {
    super(props);
    verifyLogin().then(token => {
      this.setState({ accessToken: token });
    });
  }

  logout = (removeToken = true) => {
    // this.setState(
    //   {
    //     isLoggedIn: false,
    //     accessToken: null
    //   },
    //   this.updateRavenUserContextFromState
    // );
    // if (removeToken) {
    //   removeAccessToken();
    // }
  };

  createProviderValue = () => {
    return {
      accessToken: getAccessToken(),
      axios: this.createAxiosInstance(),
      user: getUserData()
    };
  };

  createAxiosInstance = () => {
    const instance = axios.create({
      baseURL: API_ROOT,
      paramsSerializer: params =>
        JSON.stringify(params, { arrayFormat: "repeat", skipNulls: true })
    });
    instance.interceptors.request.use(request => {
      let token = this.state.accessToken;
      request.headers.Authorization = `Token ${token}`;

      //show global loading indicator
      document.body.classList.add("loading-indicator");
      return request;
    }, Promise.reject);
    instance.interceptors.response.use(
      response => {
        // hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      },
      error => {
        // hide global loading indicator
        document.body.classList.remove("loading-indicator");
        if (error.response && [401, 403].includes(error.response.status)) {
          this.logout();
        }
        return Promise.reject(error);
      }
    );
    return instance;
  };

  render() {
    return (
      <AuthenticatedContext.Provider value={this.createProviderValue()}>
        {this.renderBody()}
      </AuthenticatedContext.Provider>
    );
  }

  renderBody() {
    if (this.state && this.state.accessToken) {
      const userData = getUserData();
      const childrenWithProps = React.Children.map(
        // eslint-disable-next-line react/prop-types
        this.props.children,
        child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { user: userData });
          }
          return child;
        }
      );
      return childrenWithProps;
    } else {
      return (
        <BrowserRouter>
          <Switch />
        </BrowserRouter>
      );
    }
  }
}
export const Authenticated = AuthenticatedComponent;

export function withAxios(Component) {
  // eslint-disable-next-line react/display-name
  return props => {
    return (
      <AuthenticatedContext.Consumer>
        {({ axios, user }) => (
          <Component {...props} axios={axios} user={user} />
        )}
      </AuthenticatedContext.Consumer>
    );
  };
}

export function getAccessToken() {
  let data = localStorage.getItem(ACCESS_TOKEN_KEY);
  let token = data && JSON.parse(data).token;
  return token;
}

export function getUserData() {
  const value = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (value) {
    const data = JSON.parse(value);
    return {
      username: data.username,
      user_id: data.user_id,
      roles: data.roles,
      isStaff: data.is_staff,
      activeModules: data.active_modules,
      preferences: data.preferences
    };
  }
}

export function setAccessToken(data) {
  // console.log("setAccessToken", data);
  localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(data));
}

export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}
