// @material-ui/icons
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
// import LocalLibrary from "@material-ui/icons/LocalLibrary";
import Reorder from "@material-ui/icons/Reorder";
import Class from "@material-ui/icons/Class";
import Dashboard from "@material-ui/icons/Dashboard";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import FitnessCenter from "@material-ui/icons/FitnessCenter";
import Person from "@material-ui/icons/Person";
// import Chat from "@material-ui/icons/Chat";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
// import ContentPaste from "@material-ui/icons/ContentPaste";
// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import JournalsPage from "views/Journals/Journals.jsx";
import ActivitiesPage from "views/Activities/ActivitiesPage.jsx";
import ActivityDetailsPage from "views/Activities/ActivityDetails.jsx";
import PaceCalculator from "views/PaceCalculator/PaceCalculator.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import QuizList from "views/QuizListing/QuizListing.jsx";
import QuizResults from "views/QuizResults/QuizResults.jsx";
// import ChatPage from "views/ChatPage/ChatPage.jsx";
import CalendarPage from "views/Calendar/Calendar.jsx";
// import LibraryPage from "views/Library/Library.jsx";
// import AppointmentPage from "views/BookAppointment/BookAppointment.jsx";
import PlanPage from "views/PlanUser/Plan.jsx";
import QuizResponseUser from "views/QuizResponseUser/QuizResponseUser.jsx";

import { withAxios } from "containers/authenticated.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: withAxios(DashboardPage)
  },
  {
    path: "/activities",
    sidebarName: "Activities",
    navbarName: "Activities",
    icon: FitnessCenter,
    component: withAxios(ActivitiesPage)
  },
  {
    path: "/activity-details/:activityId",
    sidebarName: "Activities",
    navbarName: "Activities",
    icon: FitnessCenter,
    component: withAxios(ActivityDetailsPage),
    hide: true
  },
  {
    path: "/journals",
    sidebarName: "Journals",
    navbarName: "Daily Journals",
    icon: NoteAddIcon,
    component: withAxios(JournalsPage),
    hide: true // Temporary
  },
  {
    path: "/myhealthplan",
    sidebarName: "My Health Plan",
    navbarName: "My Health Plan",
    icon: Reorder,
    component: withAxios(PlanPage),
    hide: true // Temporary
  },
  {
    path: "/mysessions",
    sidebarName: "Training Calendar",
    navbarName: "Training Calendar",
    icon: Class,
    component: withAxios(CalendarPage)
  },
  // {
  //   path: "/mylivinglibrary",
  //   sidebarName: "My Living Library",
  //   navbarName: "My Living Library",
  //   icon: LocalLibrary,
  //   component: withAxios(LibraryPage)
  // },
  // {
  //   path: "/chat",
  //   sidebarName: "Chat",
  //   navbarName: "Chat",
  //   icon: Chat,
  //   component: withAxios(ChatPage)
  // },
  {
    path: "/user",
    sidebarName: "About Me",
    navbarName: "About Me",
    icon: Person,
    component: withAxios(UserProfile)
  },
  {
    path: "/quizresponse/:quizName",
    sidebarName: "Quiz Responses",
    navbarName: "Quiz Responses",
    icon: QuestionAnswer,
    component: withAxios(QuizResponseUser),
    hide: true
  },
  {
    path: "/quiz/:quizName",
    sidebarName: "Quiz",
    navbarName: "Quiz",
    icon: QuestionAnswer,
    component: withAxios(QuizList),
    hide: true
  },
  {
    path: "/results/:quizName",
    sidebarName: "Results",
    navbarName: "Results",
    icon: QuestionAnswer,
    component: withAxios(QuizResults),
    hide: true
  },
  {
    path: "/pacecalculator",
    sidebarName: "Pace Calculator",
    navbarName: "Pace-Calculator",
    icon: DirectionsRun,
    component: withAxios(PaceCalculator)
  },
  // {
  //   path: "/scheduleappointment",
  //   sidebarName: "Schedule Appointment",
  //   navbarName: "Schedule Appointment",
  //   icon: QuestionAnswer,
  //   component: withAxios(AppointmentPage),
  //   hide: true
  // },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
