import { API_ROOT } from "config.js";

const USER_CALENDAR_ENDPOINT = `${API_ROOT}/api/health/calendar/`;

export async function getUserCalendar(
  axios,
  userId,
  fromTime,
  toTime,
  getAdvisorSlots
) {
  let url = `${USER_CALENDAR_ENDPOINT}?from_time=${fromTime}&to_time=${toTime}`;
  if (userId) {
    url = `${USER_CALENDAR_ENDPOINT}?userId=${userId}&from_time=${fromTime}&to_time=${toTime}`;
  }
  if (getAdvisorSlots === true) {
    url = `${url}&&get_advisor_slots=true`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getEventDetails(axios, eventId) {
  const response = await axios.get(`${USER_CALENDAR_ENDPOINT}${eventId}/`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function createEvent(axios, eventData) {
  const response = await axios.post(`${USER_CALENDAR_ENDPOINT}`, eventData);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function deleteEvent(axios, eventId) {
  const response = await axios.delete(`${USER_CALENDAR_ENDPOINT}${eventId}/`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
