/* eslint-disable react/prop-types */
import React, { Component } from "react";

import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import ReactEcharts from "echarts-for-react";

import Table from "components/Table/Table.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {};

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getXAxisType(chartType) {
    if (chartType === "echarts_timeseries_line") {
      return "category";
    }
    if (chartType === "big_number") {
      return "category";
    }
    if (chartType === "pie") {
      return "pie";
    }
    if (chartType === "table") {
      return "table";
    }
    if (chartType === "gauge_chart") {
      return "gauge";
    }
  }

  getYAxisType(chartType) {
    if (chartType === "echarts_timeseries_line") {
      return "line";
    }
    if (chartType === "big_number") {
      return "line";
    }
    if (chartType === "pie") {
      return "pie";
    }
    if (chartType === "table") {
      return "table";
    }
    if (chartType === "gauge_chart") {
      return "gauge";
    }
  }

  formatDataValue(value, format) {
    if (format === "SMART_NUMBER") {
      return value.toFixed(2);
    }
    return value;
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { chartTitle, chartTitleColor, type, chartConfig } = this.props;

    if (!chartConfig) return null;

    // console.log("chartTitle", chartTitle, "chartConfig", chartConfig);

    const chartType = this.getXAxisType(chartConfig.viz_type);
    const xAxisColumnName = chartConfig.data.colnames[0];
    const yAxisColumnNames = chartConfig.data.colnames.slice(1);
    const dataValues = chartConfig.data.data;

    const legends = chartConfig.data.colnames;

    // console.log("chartType", chartType);
    // console.log("xAxisColumnName", xAxisColumnName);
    // console.log("yAxisColumnNames", yAxisColumnNames);
    if (yAxisColumnNames.length === 0) return null;

    const xAxisTypeTime = xAxisColumnName.includes("timestamp");

    let options = {};
    let series = [];
    let xAxisKey = {
      // name: xAxisColumnName,
      // description: xAxisColumnName,
      type: chartType,
      data: dataValues.map(dataPoint => {
        if (xAxisTypeTime) {
          return moment
            .utc(dataPoint[xAxisColumnName])
            .local()
            .format("MMMM Do YYYY");
        }
        return dataPoint[xAxisColumnName];
      }),
      axisPointer: {
        type: "shadow"
      }
    };

    let yAxisKeys = yAxisColumnNames.map(colName => {
      return {
        // name: colName,
        // description: colName,
        type: "value",
        axisLabel: {
          formatter: "{value}"
        }
      };
    });

    if (chartType === "category") {
      options["xAxis"] = [xAxisKey];
      options["yAxis"] = yAxisKeys;

      options["tooltip"] = {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999"
          }
        }
      };

      if (legends) {
        options["legend"] = {
          type: "scroll",
          data: legends
        };
      }

      series = yAxisColumnNames.map(colName => {
        return {
          name: colName,
          type: this.getYAxisType(chartConfig.viz_type),
          tooltip: {
            valueFormatter: function(value) {
              return value;
            }
          },
          data: dataValues.map(dataPoint =>
            this.formatDataValue(
              dataPoint[colName],
              chartConfig && chartConfig.y_axis_format
            )
          )
        };
      });
    } else if (chartType === "pie") {
      options["tooltip"] = {
        trigger: "item"
      };
      options["legend"] = {
        orient: "vertical",
        left: "left"
      };
      series = yAxisColumnNames.map(colName => {
        return {
          name: colName,
          type: this.getYAxisType(chartConfig.viz_type),
          tooltip: {
            valueFormatter: function(value) {
              return value;
            }
          },
          data: dataValues.map(dataPoint => {
            return {
              name: dataPoint[xAxisColumnName],
              value: this.formatDataValue(
                dataPoint[colName],
                chartConfig && chartConfig.y_axis_format
              )
            };
          })
        };
      });
    } else if (chartType === "table") {
      return this.renderTableChart(chartConfig);
    } else if (chartType === "gauge") {
      series = yAxisColumnNames.map(colName => {
        return {
          name: colName,
          type: "gauge",
          progress: {
            show: true
          },
          detail: {
            valueAnimation: true,
            formatter: "{value}"
          },
          data: dataValues.map(dataPoint => {
            return {
              name: dataPoint[xAxisColumnName],
              value: this.formatDataValue(
                dataPoint[colName],
                chartConfig && chartConfig.y_axis_format
              )
            };
          })
        };
      });
    }

    // console.log("series", series);
    options["series"] = series;

    // options["toolbox"] = {
    //   feature: {
    //     dataView: { show: true, readOnly: false },
    //     magicType: { show: true, type: ["line", "bar"] },
    //     restore: { show: true },
    //     saveAsImage: { show: true }
    //   }
    // };

    if (chartTitle) {
      options["title"] = {
        text: chartTitle,
        top: "0px",
        left: "left",
        textStyle: {
          color: chartTitleColor || "#235894"
        }
      };
    }

    if (type === "htmlText") {
      return ReactHtmlParser(series);
    } else if (type === "chart") {
      return <ReactEcharts option={options} />;
    }
    return "Invalid chart type";
  }

  beautifyNames(namesList) {
    return namesList.map(name => name.replaceAll("_", " ").toUpperCase());
  }

  renderTableChart(chartConfig) {
    const colNames =
      (chartConfig && chartConfig.data && chartConfig.data.colnames) || [];
    const data =
      (chartConfig && chartConfig.data && chartConfig.data.data) || [];
    const tableData = data.map(dataPoint =>
      Object.keys(dataPoint).map(key => {
        if (isNaN(dataPoint[key])) return dataPoint[key];

        if (key.includes("gmt") || key.includes("_time")) {
          return moment
            .utc(dataPoint[key])
            .local()
            .format("MMMM Do YYYY HH:MM");
        }

        return dataPoint[key] && dataPoint[key].toFixed(2);
      })
    );
    return (
      <Table
        tableHeaderColor="primary"
        tableHead={this.beautifyNames(colNames)}
        tableData={tableData}
      />
    );
  }
}

export default withStyles(styles)(Chart);
