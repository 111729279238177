/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import NavPills from "components/NavPills/NavPills.js";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";

import { DATE_DISPLAY_FORMAT_DETAILED, dayjs } from "utils/time.jsx";

import {
  getData,
  updateData,
  updateHealthMetricsData,
  getHealthMetricsData
} from "services/userDataProvider.js";
import { Input } from "@material-ui/core";
import { MEDIA_ROOT } from "config.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  infoHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    background: "hsla(0,0%,98%,.85)",
    boxShadow: "0px 0px 1px 0 hsl(0deg 0% 6% / 40%)",
    borderRadius: "20px"
  },
  updateInfo: {
    color: "blue"
  }
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedData: {},
      userHealthMetrics: {}
    };
  }

  updateProfile = e => {
    updateData(
      this.props.axios,
      this.props.user.user_id,
      this.state.updatedData
    );
  };

  updateHealthMetrics = e => {
    updateHealthMetricsData(
      this.props.axios,
      this.props.user.user_id,
      this.state.userHealthMetrics
    );
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let updatedData = this.state.updatedData;
      updatedData["avatarFile"] = file;
      this.setState({ updatedData: updatedData });
    }
  };

  onChange = name => e => {
    let updatedData = this.state.updatedData;
    updatedData[name] = e.target.value;
    this.setState({ [name]: e.target.value, updatedData: updatedData });
  };

  onHealthMetricChange = name => e => {
    let updatedData = this.state.userHealthMetrics || {};
    updatedData[name] = e.target.value;
    this.setState({ userHealthMetrics: updatedData });
  };

  componentDidMount() {
    getData(this.props.axios, this.props.user.user_id).then(data =>
      this.setState({ ...data })
    );
    getHealthMetricsData(this.props.axios, this.props.user.user_id).then(data =>
      this.setState({ userHealthMetrics: data })
    );
  }

  renderProfileDetails() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Profile details</h4>
          <p className={classes.cardCategoryWhite}>
            Update the profile details
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Username"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.username,
                  onChange: this.onChange("username")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Email address"
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.email,
                  onChange: this.onChange("email")
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="First Name"
                id="first_name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.first_name,
                  onChange: this.onChange("first_name")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Last Name"
                id="last_name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.last_name,
                  onChange: this.onChange("last_name")
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="City"
                id="city"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.city,
                  onChange: this.onChange("city")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Country"
                id="country"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.country,
                  onChange: this.onChange("country")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Postal Code"
                id="postal_code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.postal_code,
                  onChange: this.onChange("postal_code")
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
              <CustomInput
                labelText={""}
                id="about_me"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.about_me,
                  onChange: this.onChange("about_me"),
                  multiline: true,
                  rows: 5
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <InputLabel style={{ color: "#AAAAAA" }}>Avatar</InputLabel>
              <Input type="file" onChange={this.onFileLoad} />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button color="info" onClick={this.updateProfile}>
            Update Profile
          </Button>
        </CardFooter>
      </Card>
    );
  }

  renderHealthMetrics() {
    const { classes } = this.props;
    const { userHealthMetrics } = this.state;
    const lastUpdatedTime = userHealthMetrics.updated_at;
    return (
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>Health Metrics</h4>
          <p className={classes.cardCategoryWhite}>
            Keep the health metrics updated
          </p>
          {lastUpdatedTime != null && (
            <div style={styles.updateInfo}>
              <label style={styles.updateInfoLabel}>Last update: </label>
              <large>
                {dayjs(lastUpdatedTime).format(DATE_DISPLAY_FORMAT_DETAILED)},{" "}
                {dayjs(lastUpdatedTime).fromNow()}
              </large>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Age"
                id="Age"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.age,
                  onChange: this.onHealthMetricChange("age")
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Weight(KG)"
                id="Weight"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.weight,
                  onChange: this.onHealthMetricChange("weight")
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Height(CM)"
                id="Height(CM)"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.height,
                  onChange: this.onHealthMetricChange("height")
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="BMI"
                id="BMI"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.bmi,
                  onChange: this.onHealthMetricChange("bmi")
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Resting Heart Rate(BPM)"
                id="Resting Heart Rate(BPM)"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.resting_heart_rate,
                  onChange: this.onHealthMetricChange("resting_heart_rate")
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Max Heart Rate(BPM)"
                id="Max Heart Rate(BPM)"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.max_heart_rate,
                  onChange: this.onHealthMetricChange("max_heart_rate")
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="VO2 Max(ml/kg/min)"
                id="VO2 Max(ml/kg/min)"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  disable: true,
                  value: userHealthMetrics.vo2_max,
                  onChange: this.onHealthMetricChange("vo2_max")
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <CustomInput
                labelText="Lactate Threshold (BPM)"
                id="Lactate Threshold (BPM)"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{
                  type: "number",
                  value: userHealthMetrics.lactate_threshold,
                  onChange: this.onHealthMetricChange("lactate_threshold")
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter>
          <Button color="info" onClick={this.updateHealthMetrics}>
            Update
          </Button>
        </CardFooter>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    let availableTabs = [
      {
        tabButton: "Profile",
        tabIcon: AccountCircleIcon,
        tabContent: this.renderProfileDetails()
      },
      {
        tabButton: "Metrics",
        tabIcon: FitnessCenterIcon,
        tabContent: this.renderHealthMetrics()
      }
    ];
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={`${MEDIA_ROOT}${this.state.avatar}`} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>{this.state.roles}</h6>
                <h4 className={classes.cardTitle}>{this.state.first_name}</h4>
                <p className={classes.description}>{this.state.about_me}</p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <NavPills
              color="warning"
              vertical={{
                tabsGrid: { xs: 12, sm: 12, md: 12 },
                contentGrid: { xs: 12, sm: 12, md: 12 }
              }}
              tabs={availableTabs}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(UserProfile);
