import { API_ROOT } from "config.js";

const USER_DETAILS_ENDPOINT = `${API_ROOT}/api/users/`;
const NOTIFICATIONS_ENDPOINT = `${API_ROOT}/api/notifications/`;
const ACTIVITIES_ENDPOINT = `${API_ROOT}/api/activities/`;

export async function getData(axios, user_id) {
  let url = `${USER_DETAILS_ENDPOINT}`;
  if (user_id) {
    url = `${USER_DETAILS_ENDPOINT}${user_id}/`;
  }
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateData(axios, user_id, userData) {
  let payload = userData;
  if (userData.avatarFile) {
    payload = new FormData();
    payload.append("file", userData.avatarFile);
    delete userData["avatarFile"];

    const json = JSON.stringify(userData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}${user_id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function create(axios, user_id, userData) {
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}${user_id}/`,
    userData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getNotifications(axios) {
  const response = await axios.get(`${NOTIFICATIONS_ENDPOINT}`);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function readNotification(axios, notification_id) {
  let payload = {
    unread: false
  };
  const response = await axios.post(
    `${NOTIFICATIONS_ENDPOINT}${notification_id}/`,
    payload
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getActivities(
  axios,
  userId,
  platform,
  fetchTrainees,
  activity_id,
  startTime,
  endTime
) {
  let url = `${ACTIVITIES_ENDPOINT}`;
  if (activity_id) {
    url = `${ACTIVITIES_ENDPOINT}${activity_id}`;
  } else if (platform || fetchTrainees || userId) {
    url = `${ACTIVITIES_ENDPOINT}?`;
    if (platform) {
      url += `platform=${platform}`;
    }
    if (fetchTrainees) {
      url += `&includeTrainees=${fetchTrainees}`;
    }
    if (userId) {
      url += `&userId=${userId}`;
    }
    if (startTime) {
      url += `&startTime=${startTime}`;
    }
    if (endTime) {
      url += `&endTime=${endTime}`;
    }
  }

  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateActivity(axios, activityId, activityData) {
  const response = await axios.put(
    `${ACTIVITIES_ENDPOINT}${activityId}/`,
    activityData
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function registerUserDevice(axios, deviceToken) {
  const response = await axios.post(
    `${USER_DETAILS_ENDPOINT}register_device_token/`,
    {
      deviceToken: deviceToken
    }
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function getHealthMetricsData(axios, user_id) {
  let url = `${USER_DETAILS_ENDPOINT}`;
  if (user_id) {
    url = `${USER_DETAILS_ENDPOINT}${user_id}`;
  }
  url = url + "/health-metrics/";
  const response = await axios.get(url);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}

export async function updateHealthMetricsData(axios, user_id, userData) {
  let url = `${USER_DETAILS_ENDPOINT}`;
  if (user_id) {
    url = `${USER_DETAILS_ENDPOINT}${user_id}`;
  }
  url = url + "/health-metrics/";
  let payload = userData;
  if (userData.avatarFile) {
    payload = new FormData();
    payload.append("file", userData.avatarFile);
    delete userData["avatarFile"];

    const json = JSON.stringify(userData);
    const blob = new Blob([json], {
      type: "application/json"
    });
    payload.append("document", blob);
  }
  const response = await axios.post(url, payload);
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  const data = response.data;
  return data;
}
