/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Input } from "@material-ui/core";

import {
  getMyUsers,
  getUserPlans,
  updateUserPlan
} from "../../services/advisorUsers.js";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const ADVICES = ["DIETARY", "FORMULA", "ROUTINE", "EXERCISE"];

class Plan extends React.Component {
  state = {
    users: []
  };

  componentDidMount() {
    getMyUsers(this.props.axios).then(data => {
      this.setState({
        users: data
      });
    });
  }

  separateHealthAdvices(adviceData) {
    let existingAdvices = adviceData.health_advices || {};
    let healthAdvices = {};
    existingAdvices.forEach(thisAdvice => {
      if (thisAdvice.name) {
        healthAdvices[thisAdvice.name] = thisAdvice.details || [];
      }
    });
    ADVICES.forEach(adviceName => {
      if (healthAdvices[adviceName] === undefined) {
        healthAdvices[adviceName] = [];
      }
    });
    this.setState({ healthAdvices: healthAdvices });
  }

  handleUserChange = value => {
    this.setState(
      {
        userId: value,
        adviceData: {}
      },
      () => {
        getUserPlans(this.props.axios, value).then(data => {
          const recommData = data.length > 0 ? data[0] : undefined;
          recommData && this.separateHealthAdvices(recommData);
          recommData && this.setState({ adviceData: recommData });
        });
      }
    );
  };

  handleChange = (name, inputType) => event => {
    let value = null;
    let currentState = this.state.adviceData || {};
    if (inputType === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    currentState[[name]] = value;
    this.setState({ adviceData: currentState });
  };

  onFileLoad = e => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.setState({ planCsv: file });
    }
  };

  handleSubmit = recommId => e => {
    const healthAdvices = this.state.healthAdvices || {};
    let healthAdvicesData = Object.keys(healthAdvices).map(adviceName => {
      return {
        name: adviceName,
        details: healthAdvices[adviceName]
      };
    });
    let adviceData = this.state.adviceData;
    adviceData["health_advices"] = healthAdvicesData;
    updateUserPlan(
      this.props.axios,
      this.state.userId,
      recommId,
      adviceData,
      this.state.planCsv
    ).then(data => {
      this.separateHealthAdvices(data);
      this.setState({ adviceData: data });
    });
  };

  handleAdviceChange = (adviceName, idx, fieldName) => event => {
    // console.log("handleAdviceChange", adviceName, idx, fieldName);
    let healthAdvices = this.state.healthAdvices;
    let healthAdvice = healthAdvices[adviceName];
    healthAdvice[idx][fieldName] = event.target.value;
    healthAdvices[adviceName] = healthAdvice;
    this.setState({ healthAdvices: healthAdvices });
  };

  addAdvice = adviceName => e => {
    let healthAdvices = this.state.healthAdvices;
    let healthAdvice = healthAdvices[adviceName];
    healthAdvice.push({
      name: "",
      description: ""
    });
    healthAdvices[adviceName] = healthAdvice;
    this.setState({ healthAdvices: healthAdvices });
  };

  removeAdvice = (adviceName, idx) => event => {
    let healthAdvices = this.state.healthAdvices;
    let healthAdvice = healthAdvices[adviceName].filter(
      (_ad, aId) => aId !== idx
    );
    healthAdvices[adviceName] = healthAdvice;
    this.setState({ healthAdvices: healthAdvices });
  };

  renderRecommendation() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    const { adviceData } = this.state;
    const healthAdvices = this.state.healthAdvices;
    const inputState = this.state.inputState || {};
    // console.log("healthAdvices", healthAdvices);
    if (adviceData === undefined) {
      return null;
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>
                {`Health Recommendation: ${
                  adviceData.recommendation_type
                    ? adviceData.recommendation_type
                    : "No Plan"
                }`}
              </h4>
              <p className={classes.cardCategoryWhite}>{`Advisor: ${
                adviceData.advisor ? adviceData.advisor : "NA"
              }`}</p>
            </CardHeader>
            <CardBody>
              <CustomInput
                success={inputState["start_time"] === "success"}
                error={inputState["start_time"] === "error"}
                labelText={
                  <span>
                    {"Start Date"}
                    <small>(required)</small>
                  </span>
                }
                id={`input-start-time`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "date",
                  value: adviceData.start_time,
                  onChange: this.handleChange("start_time", "date")
                }}
              />
              <CustomInput
                success={inputState["end_time"] === "success"}
                error={inputState["end_time"] === "error"}
                labelText={
                  <span>
                    {"End Date"}
                    <small>(required)</small>
                  </span>
                }
                id={`input-end-time`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "date",
                  value: adviceData.end_time,
                  onChange: this.handleChange("end_time", "date")
                }}
              />
              <CustomInput
                success={inputState["details"] === "success"}
                error={inputState["details"] === "error"}
                labelText={
                  <span>
                    {"Details"}
                    <small>(required)</small>
                  </span>
                }
                id={`input-end-time`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  value: adviceData.details && adviceData.details.details,
                  onChange: this.handleChange("details", "text")
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="no"
                    color="primary"
                    onChange={this.handleChange(
                      "override_calendar",
                      "checkbox"
                    )}
                  />
                }
                label="Override calendar events?"
              />
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel style={{ color: "#AAAAAA" }}>File</InputLabel>
                <Input type="file" onChange={this.onFileLoad} />
              </GridItem>
              {healthAdvices &&
                Object.keys(healthAdvices).map(adviceName => {
                  return this.renderAdviceBlock(
                    adviceName,
                    healthAdvices[adviceName]
                  );
                })}
            </CardBody>
            <CardFooter>
              <Button color="info" onClick={this.handleSubmit(adviceData.id)}>
                Save
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  renderUsersSelectionBox() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText={"User"}
            items={userOptions}
            onChange={this.handleUserChange}
          />
        </GridItem>
      </GridContainer>
    );
  }

  renderHealthAdviceForm(adviceName, data, idx) {
    return (
      <GridContainer key={`advice-${idx}`}>
        <GridItem xs={11} sm={3} md={3}>
          <CustomInput
            // success={inputState["details"] === "success"}
            // error={inputState["details"] === "error"}
            labelText={
              <span>
                {"Name"}
                <small>(required)</small>
              </span>
            }
            id={`input-name-${idx}-${adviceName}`}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              value: data && data.name,
              onChange: this.handleAdviceChange(adviceName, idx, "name")
            }}
          />
        </GridItem>
        <GridItem xs={11} sm={8} md={8}>
          <CustomInput
            // success={inputState["details"] === "success"}
            // error={inputState["details"] === "error"}
            labelText={
              <span>
                {"Details"}
                <small>(required)</small>
              </span>
            }
            id={`input-details-${idx}-${adviceName}`}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              value: data && data.description,
              onChange: this.handleAdviceChange(adviceName, idx, "description")
            }}
          />
        </GridItem>
        <GridItem xs={1} sm={1} md={1}>
          <CloseIcon onClick={this.removeAdvice(adviceName, idx)} />
        </GridItem>
      </GridContainer>
    );
  }

  renderAdviceBlock(adviceName, adviceData) {
    // console.log("renderAdviceBlock", adviceName, adviceData);
    return (
      <div>
        <h6>{adviceName}</h6>
        {adviceData.map((data, idx) => {
          // console.log("renderAdviceBlock", adviceName, idx, data);
          return this.renderHealthAdviceForm(adviceName, data, idx);
        })}
        <AddIcon onClick={this.addAdvice(adviceName)} />
      </div>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <div>
        {this.renderUsersSelectionBox()}
        {this.renderRecommendation()}
      </div>
    );
  }
}

Plan.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Plan);
