import React, { Component } from "react";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import styles from "assets/jss/material-dashboard-react/customSelectStyle.jsx";

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multipleSelect: []
    };
  }

  handleMultiple = event => {
    const value = event.target.value;
    this.setState({ multipleSelect: value}, () => {
      this.props.onChange && this.props.onChange(value);
    });
  };

  searchTermUpdated = (event, searchMethod, minLength) => {
    let text = event.target.value;
    if (minLength === undefined) {
      minLength = 3;
    }
    if( text.length >= minLength) {
      searchMethod(text).then(data => {
        this.setState({ items: data});
      });
    }
    this.setState({ selectedItem: undefined });
    this.props.onChange && this.props.onChange(null);
  };

  selectedSearchable = item => event => {
    const value = item.value;
    this.setState({ selectedItem: item }, () => {
      this.props.onChange && this.props.onChange(value);
    });
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const {
      labelText,
      classes,
      multiple,
      items,
      error,
      success,
      searchable,
      inputProps,
      searchMethod,
      minSearchTermLength
    } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
          {labelText}
        </InputLabel>
        {searchable !== undefined ? (
          <>
            <Input
              value={this.state.selectedItem && this.state.selectedItem.text}
              {...inputProps}
              onChange={e => this.searchTermUpdated(e, searchMethod, minSearchTermLength)}
            />
            {this.state.selectedItem === undefined && this.state.items &&
              this.state.items.map((item, idx) => (
                <MenuItem
                  key={`select-${idx}`}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelectedMultiple
                  }}
                  value={item.value}
                  onClick={this.selectedSearchable(item)}
                >
                  {item.text}
                </MenuItem>
              ))}
          </>
        ) : (
          <Select
            multiple={multiple}
            value={this.state.multipleSelect}
            onChange={this.handleMultiple}
            MenuProps={{
              className: classes.selectMenu,
              classes: { paper: classes.selectPaper }
            }}
            classes={{ select: classes.select }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select"
            }}
          >
            {items &&
              items.map(item => (
                <MenuItem
                  key={`select-${item.text}`}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelectedMultiple
                  }}
                  value={item.value}
                >
                  {item.text}
                </MenuItem>
              ))}
          </Select>
        )}
        {error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : success ? (
          <Check
            className={classes.feedback + " " + classes.labelRootSuccess}
          />
        ) : null}
      </FormControl>
    );
  }
}

export default withStyles(styles)(CustomSelect);
