import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Calendar as CalendarComponent,
  momentLocalizer
} from "react-big-calendar";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Heading from "components/Heading/Heading.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

import { getActivities } from "services/userDataProvider.js";
import {
  getUserCalendar,
  createEvent,
  deleteEvent,
  getEventDetails
} from "../../services/calendarDataProvider";
import { getMyUsers } from "../../services/advisorUsers.js";

const styles = {
  trainingsCalenderContainer: {
    padding: "0px !important"
  },
  trainingsCardHeader: {
    padding: "15px !important",
    marginTop: "-20px !important",
    borderRadius: "3px !important",
    background: "linear-gradient(60deg, #275b95, #00acc1) !important",
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4) !important"
  },
  actionButtons: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "space-evenly"
  }
};

const localizer = momentLocalizer(moment);

const EVENT_TRAINING = "Training";
const EVENT_ACTIVITY = "Activity";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      sessions: []
    };
  }

  getUserActivities = (startTime, endTime) => {
    getActivities(
      this.props.axios,
      this.state.userId,
      null,
      false,
      null,
      startTime,
      endTime
    ).then(data => {
      let activitySessions = data.results.map(act => {
        return {
          event: {
            description: act.activity_name,
            end: act.start_time_gmt,
            start: act.start_time_gmt,
            title: act.activity_name,
            type: EVENT_ACTIVITY,
            ...act
          },
          title: act.activity_name,
          start: moment(act.start_time_gmt).toDate(),
          end: moment(act.start_time_gmt)
            .add(act.duration_seconds, "seconds")
            .toDate(),
          type: EVENT_ACTIVITY
        };
      });
      const updatedSessions = [...this.state.sessions, ...activitySessions];
      this.setState({
        activities: data.results,
        sessions: updatedSessions
      });
    });
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const fromTime = moment()
      .clone()
      .startOf("month")
      .format(moment.defaultFormatUtc);
    const toTime = moment()
      .clone()
      .endOf("month")
      .format(moment.defaultFormatUtc);

    // eslint-disable-next-line react/prop-types
    getUserCalendar(this.props.axios, this.state.userId, fromTime, toTime).then(
      data => {
        let allSessions = [];
        data.forEach(calendarData => {
          calendarData.occurences.forEach(session => {
            return allSessions.push({
              event: {
                ...session.event,
                type: EVENT_TRAINING
              },
              title: session.event.title,
              start: new Date(session.event.start),
              end: new Date(session.event.end),
              type: EVENT_TRAINING
            });
          });
        });
        this.setState({ userId: null, sessions: allSessions }, () => {
          this.getUserActivities(fromTime, toTime);
        });
      }
    );

    getMyUsers(this.props.axios).then(data => {
      this.setState({
        users: data
      });
    });
  }

  calendarTimeRangeChanged = ({ start, end }) => {
    let fromTime = moment(start)
      .utc()
      .format(moment.defaultFormatUtc);
    let toTime = moment(end)
      .utc()
      .format(moment.defaultFormatUtc);
    getUserCalendar(this.props.axios, this.state.userId, fromTime, toTime).then(
      data => {
        let allSessions = [];
        data.forEach(calendarData => {
          calendarData.occurences.forEach(session => {
            return allSessions.push({
              event: {
                ...session.event,
                type: EVENT_TRAINING
              },
              title: session.event.title,
              start: new Date(session.event.start),
              end: new Date(session.event.end),
              type: EVENT_TRAINING
            });
          });
        });
        this.setState({ sessions: allSessions }, () => {
          this.getUserActivities(fromTime, toTime);
        });
      }
    );
  };

  handleClick = (name, idx) => e => {
    if (name === "cancel") {
      deleteEvent(this.props.axios, idx).then(e => {
        let sessions = this.state.sessions.filter(
          session => idx !== session.event.id
        );
        this.setState({ sessions: sessions, selectedEvent: undefined });
      });
    } else if (name === "details") {
      getEventDetails(this.props.axios, idx);
    } else if (name === "back") {
      this.setState({ selectedEvent: undefined });
    }
  };

  selectedEvent = event => {
    const currentSessionIdx = this.state.sessions.findIndex(
      et => et.event.id === event.event.id
    );
    this.setState({
      currentSessionIdx: currentSessionIdx,
      totalSessions: this.state.sessions.length,
      selectedEvent: event
    });
  };

  selectNextEvent() {
    let currentSessionIdx = this.state.currentSessionIdx || 0;
    this.setState({
      currentSessionIdx: currentSessionIdx + 1,
      selectedEvent: this.state.sessions[currentSessionIdx + 1]
    });
  }

  selectPreviousEvent() {
    let currentSessionIdx = this.state.currentSessionIdx || 0;
    this.setState({
      currentSessionIdx: currentSessionIdx - 1,
      selectedEvent: this.state.sessions[currentSessionIdx - 1]
    });
  }

  setAlert(alert) {
    this.setState({ alert: alert });
  }

  hideAlert = () => {
    this.setAlert(null);
  };

  addNewEventAlert = slotInfo => {
    const { classes } = this.props;
    this.setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Enter session title"
        onConfirm={e => this.addNewEvent(e, slotInfo)}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      />
    );
  };

  addNewEvent = (e, slotInfo) => {
    let eventData = {
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    };
    let newEvents = this.state.sessions;
    // eslint-disable-next-line react/prop-types
    createEvent(this.props.axios, eventData).then(data => {
      // console.log("created event", data);
      newEvents.push({
        event: data,
        start: slotInfo.start,
        end: slotInfo.end
      });
      this.setAlert(null);
    });
    this.setState({ sessions: newEvents });
  };

  handleUserChange = value => {
    const fromTime = moment()
      .clone()
      .startOf("month")
      .format(moment.defaultFormatUtc);
    const toTime = moment()
      .clone()
      .endOf("month")
      .format(moment.defaultFormatUtc);
    this.setState(
      {
        userId: value,
        sessions: []
      },
      () => {
        getUserCalendar(
          this.props.axios,
          this.state.userId,
          fromTime,
          toTime
        ).then(data => {
          let allSessions = [];
          data.forEach(calendarData => {
            calendarData.occurences.forEach(session => {
              return allSessions.push({
                event: {
                  ...session.event,
                  type: EVENT_TRAINING
                },
                title: session.event.title,
                start: new Date(session.event.start),
                end: new Date(session.event.end),
                type: EVENT_TRAINING
              });
            });
          });
          this.setState({ sessions: allSessions }, () => {
            this.getUserActivities(fromTime, toTime);
          });
        });
      }
    );
  };

  renderPlanDescription = description => {
    const keyNames = ["Warm up:", "Workout:", "Cool down:"];
    keyNames.forEach(keyName => {
      description = description.replace(keyName, ";;; " + keyName);
    });
    const lines = description.split(";;;");
    return (
      <ul>
        {lines.filter(line => line.trim().length > 0).map((line, idx) => {
          return <li key={idx}>{line}</li>;
        })}
      </ul>
    );
  };

  renderSession(sessionData, showCancel) {
    const { classes } = this.props;
    if (sessionData.event === undefined) {
      return null;
    }
    // let jsonData = {};
    // try {
    //   jsonData = JSON.parse(sessionData.event.description);
    // } catch (e) {}

    // let eventType = (jsonData && jsonData.type) || "";
    // let trainingType = (jsonData && jsonData.training_type) || "";
    // let advisor = (jsonData && jsonData.advisor) || "";
    // let totalDistance = (jsonData && jsonData.total_distance) || "";

    // let trainingPlan = (jsonData && jsonData.training_plan) || [];

    // trainingPlan = trainingPlan.map(tp => {
    //   return `${tp.description}: ${tp.distance} @ ${tp.pace}`;
    // });
    let trainingType = sessionData.event.title;
    let trainingPlan = this.renderPlanDescription(
      sessionData.event.description
    );
    let advisor = sessionData.event.creator;

    let tableData = [];

    tableData.push(["Event", `${trainingType}`]);
    if (
      sessionData.event.type === EVENT_TRAINING ||
      sessionData.event.type === EVENT_ACTIVITY
    ) {
      tableData.push([
        "Time",
        `${moment(sessionData.start).format(
          "MMMM Do YYYY, hh:mm a"
        )} till ${moment(sessionData.end).format("hh:mm a")}`
      ]);
    }
    if (sessionData.event.type === EVENT_TRAINING) {
      tableData.push(["Plan", trainingPlan]);
    }
    // tableData.push(["Total Distance", totalDistance]);
    // tableData.push([
    //   "Plan",
    //   trainingPlan.map((tp, idx) => {
    //     return (
    //       <div key={`tp-${idx}`}>
    //         <p>{tp}</p>
    //         <br />
    //       </div>
    //     );
    //   })
    // ]);

    return (
      <Card className={classes.trainingsCalenderContainerCard}>
        <CardHeader className={classes.trainingsCardHeader} stats>
          <h3 className={classes.cardCategory}>{sessionData.event.title}</h3>
          <p className={classes.cardTitle}>
            {moment(sessionData.start).fromNow()}
          </p>
        </CardHeader>
        <CardBody className={classes.trainingsCalenderContainerContainer}>
          <Table tableHeaderColor="info" tableData={tableData} />
        </CardBody>
        <CardFooter>
          <div className={classes.actionButtons}>
            <Button
              color="info"
              onClick={this.handleClick("back", sessionData.event.id)}
            >
              Back
            </Button>
            <Button
              color="info"
              onClick={() => this.selectPreviousEvent()}
              disabled={this.state.currentSessionIdx <= 0}
            >
              Previous
            </Button>
            <Button
              color="info"
              onClick={() => this.selectNextEvent()}
              disabled={
                this.state.currentSessionIdx >= this.state.totalSessions
              }
            >
              Next
            </Button>
            {sessionData.event.type === EVENT_TRAINING && (
              <Button
                color="warning"
                disabled={advisor === "" || !showCancel}
                onClick={this.handleClick("cancel", sessionData.event.id)}
              >
                Cancel Session
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    );
  }

  renderUsersSelectionBox() {
    let userOptions =
      this.state.users &&
      this.state.users.map(user => {
        return { text: user.username, value: user.id };
      });
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText={"User"}
            items={userOptions}
            onChange={this.handleUserChange}
          />
        </GridItem>
      </GridContainer>
    );
  }

  renderCalendar() {
    const { classes } = this.props;
    const calendarEvents = this.state.sessions || [];

    const propGetter = (event, start, end, isSelected) => {
      return {
        style: {
          backgroundColor: event.type === EVENT_ACTIVITY ? "#3bd97a" : "#27679c"
        }
      };
    };

    return (
      <Card className={classes.trainingsCalenderContainerCard}>
        <CardBody calendar className={classes.trainingsCalenderContainer}>
          <CalendarComponent
            selectable
            localizer={localizer}
            events={calendarEvents}
            defaultView="month"
            scrollToTime={new Date(2020, 1, 1, 1)}
            defaultDate={new Date()}
            onSelectEvent={event => this.selectedEvent(event)}
            onSelectSlot={slotInfo => this.addNewEventAlert(slotInfo)}
            eventPropGetter={propGetter}
            views={["month", "agenda"]}
            onRangeChange={this.calendarTimeRangeChanged}
          />
        </CardBody>
      </Card>
    );
  }

  render() {
    // eslint-disable-next-line no-unused-vars, react/prop-types
    const { classes, user } = this.props;
    const isAdvisor = user.roles && user.roles.includes("ADVISOR");
    return (
      <div>
        <Heading
          textAlign="center"
          title="Your Trainings Calendar"
          category={
            <span>
              The calendar also shows free slots from your advisor, for you to
              book sessions.
            </span>
          }
        />
        {isAdvisor ? this.renderUsersSelectionBox() : null}
        {this.state.alert}
        {this.state.selectedEvent !== undefined
          ? this.renderSession(this.state.selectedEvent, true)
          : this.renderCalendar()}
      </div>
    );
  }
}

Calendar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Calendar);
