import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import SignIn from "layouts/SignIn/SignIn.jsx";
import ResetPassword from "layouts/ResetPassword/ResetPassword.jsx";
import SignUp from "layouts/SignUp/SignUp.jsx";
import StartPage from "layouts/StartPage/StartPage.jsx";
import Support from "layouts/Support/Support";

const indexRoutes = [
  { path: "/start", component: StartPage },
  { path: "/signin", component: SignIn },
  { path: "/resetpassword/:uid64/:token", component: ResetPassword },
  { path: "/resetpassword", component: ResetPassword },
  { path: "/signup", component: SignUp },
  { path: "/support", component: Support },
  { path: "/", component: Dashboard }
];

export default indexRoutes;
