import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

const styles = {};

class Dashboard extends React.Component {
  state = {
    distance: 0.0,
    target_time: "",
    minutes_per_km: "",
    kms_per_hour: 0.0
  };

  // eslint-disable-next-line no-unused-vars
  calculatePace = _e => {
    let target_time_string = this.state.target_time;
    let minutes_per_km_string = this.state.minutes_per_km;
    let kms_per_hour = isFinite(this.state.kms_per_hour)
      ? parseFloat(this.state.kms_per_hour)
      : 0;
    let distance = isFinite(this.state.distance)
      ? parseFloat(this.state.distance)
      : 0;

    let target_time = this.timeToHours(target_time_string);
    let target_pace = 1 / this.timeToHours(minutes_per_km_string);
    // console.log("target_time", target_time);
    // console.log("target_pace", target_pace);
    if (distance > 0.0 && target_time > 0.0) {
      // console.log("case 1");
      kms_per_hour = distance / target_time;
      minutes_per_km_string = this.hoursToTime(1 / kms_per_hour);
    } else if (distance > 0.0 && kms_per_hour > 0.0) {
      // console.log("case 2");
      minutes_per_km_string = this.hoursToTime(1 / kms_per_hour);
      target_time_string = this.hoursToTime(distance / kms_per_hour);
    } else if (distance > 0.0 && target_pace > 0.0) {
      // console.log("case 3");
      kms_per_hour = target_pace;
      target_time_string = this.hoursToTime(distance / kms_per_hour);
    } else if (target_time > 0.0 && kms_per_hour > 0.0) {
      // console.log("case 4");
      distance = target_time * kms_per_hour;
      minutes_per_km_string = this.hoursToTime(1 / kms_per_hour);
    } else if (target_time > 0.0 && target_pace > 0.0) {
      // console.log("case 5");
      kms_per_hour = target_pace;
      distance = target_time * kms_per_hour;
    }
    this.setState({
      kms_per_hour: kms_per_hour,
      minutes_per_km: minutes_per_km_string,
      distance: distance,
      target_time: target_time_string
    });
  };

  // eslint-disable-next-line no-unused-vars
  clearInputs = e => {
    this.setState({
      distance: 0.0,
      target_time: "",
      minutes_per_km: "",
      kms_per_hour: 0.0
    });
  };

  onChange = name => e => {
    // console.log(name, e.target.value);
    if (name === "minutes_per_km") {
      let target_pace = 1 / this.timeToHours(e.target.value);
      let kms_per_hour =
        target_pace > 0 ? target_pace : this.state.kms_per_hour;
      this.setState({
        kms_per_hour: kms_per_hour,
        minutes_per_km: e.target.value
      });
    } else if (name === "kms_per_hour") {
      let target_pace = isFinite(e.target.value)
        ? parseFloat(e.target.value)
        : 0;
      let minutes_per_km_string =
        target_pace > 0.0
          ? this.hoursToTime(1 / target_pace)
          : this.state.minutes_per_km;
      this.setState({
        kms_per_hour: e.target.value,
        minutes_per_km: minutes_per_km_string
      });
    } else {
      this.setState({ [name]: e.target.value });
    }
  };

  timeToHours(timeString) {
    // console.log("timeToHours", timeString);
    if (!timeString) return 0;
    let time = timeString.split(":");
    let hours = time.length > 0 ? parseInt(time[0]) : 0;
    let minutes = time.length > 1 ? parseInt(time[1]) : 0;
    let seconds = time.length > 2 ? parseInt(time[2]) : 0;

    return hours + minutes / 60 + seconds / 3600;
  }

  hoursToTime(totalHours) {
    // console.log("hoursToTime", totalHours);
    if (!isFinite(totalHours)) return "Invalid";
    let hours = Math.floor(totalHours);
    let totalMinutes = (totalHours - hours) * 60;
    let minutes = Math.floor(totalMinutes);
    let seconds = Math.floor((totalMinutes - minutes) * 60);

    hours = hours > 9 ? hours : `0${hours}`;
    minutes = minutes > 9 ? minutes : `0${minutes}`;
    seconds = seconds > 9 ? seconds : `0${seconds}`;

    return `${hours}:${minutes}:${seconds}`;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Pace Calculator</h4>
                <p className={classes.cardCategoryWhite}>Plan your Run!</p>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Distance(KMs)"
                      id="distance"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.distance,
                        onChange: this.onChange("distance")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Target Time(HH:MM:SS)"
                      id="target_time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.target_time,
                        onChange: this.onChange("target_time")
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Pace(In Minutes/KM)"
                      id="minutes_per_km"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.minutes_per_km,
                        onChange: this.onChange("minutes_per_km")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Pace(In KMs/Hour)"
                      id="kms_per_hour"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: this.state.kms_per_hour,
                        onChange: this.onChange("kms_per_hour")
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="button" color="gray" onClick={this.clearInputs}>
                  Clear
                </Button>
                <Button type="button" color="info" onClick={this.calculatePace}>
                  Calculate
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
