/* eslint-disable react/prop-types */
import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import Question from "components/Question/Question.jsx";
import { getQuestions, submitAnswer } from "../../services/quizDataProvider";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class Quiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      answerData: {}
    };
  }

  answerUpdated = questionIndex => data => {
    // console.log("answerUpdated", questionIndex, data);
    const questions = this.state.data && this.state.data.results;
    let answerData = this.state.answerData;
    answerData["question"] = questions[questionIndex];
    answerData["answer"] = data;
    this.setState({ ...this.state, answerData: answerData }, () => {
      if (questions[questionIndex].question_type === "single-select") {
        let clickHandler = this.handleClick("next");
        clickHandler();
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  handleClick = name => event => {
    const questions = (this.state.data && this.state.data.results) || [];
    let nextIndex = this.state.index;
    if (name === "finish") {
      submitAnswer(this.props.axios, this.state.answerData);
      this.props.history.push("/dashboard");
    } else if (name === "next" && this.state.answerData) {
      submitAnswer(this.props.axios, this.state.answerData);
    }
    if (name === "last" && this.state.index > 0) {
      nextIndex = nextIndex - 1;
    } else if (name === "next" && nextIndex < questions.length - 1) {
      nextIndex = nextIndex + 1;
    }
    this.setState({ index: nextIndex });
  };

  componentDidMount() {
    getQuestions(this.props.axios, this.props.name).then(data =>
      this.setState({ data: data })
    );
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { classes } = this.props;
    const questions = this.state.data && this.state.data.results;
    const totalQuestions = (questions && questions.length) || 0;
    const currentQuestionIndex = this.state.index || 0;
    // console.log("currentQuestionIndex", currentQuestionIndex);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                <p
                  className={classes.cardCategoryWhite}
                >{`Question ${currentQuestionIndex + 1}/${totalQuestions}`}</p>
              </CardHeader>
              <CardBody>
                {questions && questions[currentQuestionIndex] ? (
                  <Question
                    key={`question-${currentQuestionIndex}`}
                    data={questions[currentQuestionIndex]}
                    onChange={this.answerUpdated(currentQuestionIndex)}
                  />
                ) : null}
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  disabled={currentQuestionIndex <= 0}
                  onClick={this.handleClick("last")}
                >
                  Last
                </Button>
                {questions && currentQuestionIndex < totalQuestions - 1 ? (
                  <Button color="primary" onClick={this.handleClick("next")}>
                    Next
                  </Button>
                ) : (
                  <Button color="success" onClick={this.handleClick("finish")}>
                    Finish
                  </Button>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Quiz);
